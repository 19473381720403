// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin: 8px auto; Centers the card with vertical spacing */
  /* width: 95%;  Ensures it takes full width */
  height: 100px;
}

.text-container {
  flex: 1 1;
  padding-right: 16px;
}

.header {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.text {
  margin: 0;
  font-size: 14px;
}

.image-container {
  flex: 0.8 1; /* Reduced flex to prevent overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden; /* Ensures image stays inside */
}

.image {
  max-width: 250px; /* Adjusted size */
  max-height: 250px; /* Keeps it inside */
  object-fit: contain; /* Ensures full image is visible */
  border-radius: 4px; /* Adds a slight rounding */
}
`, "",{"version":3,"sources":["webpack://./src/components/booking/CSSStyle/CardImageRight.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,6DAA6D;EAC7D,6CAA6C;EAC7C,aAAa;AACf;;AAEA;EACE,SAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAS,EAAE,qCAAqC;EAChD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB,EAAE,+BAA+B;AACnD;;AAEA;EACE,gBAAgB,EAAE,kBAAkB;EACpC,iBAAiB,EAAE,oBAAoB;EACvC,mBAAmB,EAAE,kCAAkC;EACvD,kBAAkB,EAAE,2BAA2B;AACjD","sourcesContent":[".card-container {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 16px;\r\n  background-color: #ffffff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  /* margin: 8px auto; Centers the card with vertical spacing */\r\n  /* width: 95%;  Ensures it takes full width */\r\n  height: 100px;\r\n}\r\n\r\n.text-container {\r\n  flex: 1;\r\n  padding-right: 16px;\r\n}\r\n\r\n.header {\r\n  margin: 0;\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n}\r\n\r\n.text {\r\n  margin: 0;\r\n  font-size: 14px;\r\n}\r\n\r\n.image-container {\r\n  flex: 0.8; /* Reduced flex to prevent overflow */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100%;\r\n  overflow: hidden; /* Ensures image stays inside */\r\n}\r\n\r\n.image {\r\n  max-width: 250px; /* Adjusted size */\r\n  max-height: 250px; /* Keeps it inside */\r\n  object-fit: contain; /* Ensures full image is visible */\r\n  border-radius: 4px; /* Adds a slight rounding */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
