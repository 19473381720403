
import { User , UserInput } from "../models/User";
import { createNewUser ,getUserByPhone } from "./userService"



export const createOrGetUser = async (name : string , phone:string): Promise<User | null> => {
    const userPhone = phone.trim()
  console.log("userPhone "+userPhone)
    try {
        const existingUser = await getUserByPhone(userPhone);

        if (!existingUser || !existingUser._id) {
            const newUser: UserInput = {
                name: name,
                phone: userPhone,
                mpin: "112233",
                email: "contact@greenrides.in",
                totalRides: 0,
                userBrand: "NA",
                userRating: 0,
                status: "ACTIVE",
            };

            return await createNewUser(newUser);
        }

        return existingUser;
    } catch (error) {
        console.log("Error creating or retrieving user:", error);
        return null
    }



};

