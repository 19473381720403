// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselReview from '../components/CarouselReview';

const GrpBusinessUserApp: React.FC = () => {

  return (
    <div>
      <CarouselReview></CarouselReview>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
      Green Rides Pune Business User App
      </Typography>
    </div>
  );
};

export default GrpBusinessUserApp;
