import { LastMinuteCar } from '../models/LastMinuteCar'
import client from "./apolloClient";
import { GET_LAST_MINUTE_CARS } from './queries';
import { UPDATE_CAR_AVAILABILITY } from './mutation';


export const getLastMinuteCars = async (): Promise<LastMinuteCar[] | null> => {
    try {
        const { data } = await client.query({
            query: GET_LAST_MINUTE_CARS,
        });

        return data?.getLastMinuteCars
    } catch (error) {
        console.log('Error fetching getLastMinuteCars:', error);
        return null;
    }
};

export const updateCarAvailability = async (carId: string, fieldName: string, fieldValue: string, ): Promise<LastMinuteCar | null> => {
    try {
        const { data } = await client.mutate({
            mutation: UPDATE_CAR_AVAILABILITY,
            variables: { carId, fieldName, fieldValue, },
        });
        // refreshAppContext(data?.createBooking);
        return data?.updateCarAvailability;
    } catch (error) {
        // console.error('Error fetching create booking:', error);
        console.log("Error fetching updateCarAvailability: " + error)
        return null;
    }
}


