import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import '../App.css';
import WhatsAppButton from '../components/WhatsAppButton';
import image1 from "../assets/images/BYD1.png";
import image4 from "../assets/images/4.jpg";

const Home: React.FC = () => {

  const webUrl = process.env.REACT_APP_URL;

  const videoUrl = `${webUrl}images/ec4.mp4`;
  //const [videoUrl, setVideoUrl] = useState(`http://grpweb.centralindia.cloudapp.azure.com/images/ec${Math.floor(Math.random() * 4) + 1}.mp4`);

  return (
    < >
      {/* <CarouselComponent /> */}
      <video width="100%" autoPlay loop muted controls={false}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <br />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Pune to Mumbai Airport Cab | Innova/BYD Oneway drop @ ₹3960</Typography>
          <Typography variant="body1" textAlign="left">
            <br />
            Are you planning a one way from Pune to Mumbai Airport cab and looking for a convenient, extra large car and eco-friendly way to travel from the airport to your destination? Look no further than Green Rides Pune Cab, the premier airport cab booking service from Pune to Mumbai.
            <br /><br />
            With Green Rides Pune Cab, you can enjoy a hassle-free booking process and reliable, on-time pickup and drop-off service. Plus, you'll be doing your part to reduce your carbon footprint, as all of our cabs are eco-friendly and use clean energy sources.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <img
            src={image1}
            alt="Green Rides Cabs"
            style={{ width: '100%', height: 'auto', borderRadius: '7px' }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Premium & Luxury SUV BYD E6 | Innova/BYD E6 ₹3960</Typography>
          <Typography textAlign="left" variant="body1">
            <br />
            Our drivers are professional and courteous, and they do Pune to Mumbai Airport Drop or Pickup like the back of their hand. Whether you're headed to a hotel, a business meeting, or a tourist attraction, they'll get you there safely and efficiently.
            <br /><br />
            Pune to Mumbai Airport cab or Mumbai Airport to Pune cab Booking with Green Rides Pune Cab is easy. Simply visit our website or use WhatsApp to book your ride in advance. You can also book on-demand when you arrive at the airport.
            <br /><br />
            So why wait? Book your Pune to Mumbai airport cab or Mumbai Airport to Pune cab with Green Rides Pune Cab today and experience the best in eco-friendly transportation in Mumbai.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <video width="100%" autoPlay loop muted>
            <source src={`${webUrl}/images/ec2.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Mumbai Airport to Pune Cab - Make Our Passengers Feel Safer with Next-Gen Camera</Typography>
          <Typography textAlign="left" variant="body1">
            <br />
            We have installed KENT CamEye (Next-Gen Car Dashboard Camera) in our all cabs.
            <br /><br />
            Safe and Reliable Journey for Mumbai Airport Drop and Pickup.
            <br /><br />
            Locate our cab in case there is emergency using GPS tracking capabilities.
            <br /><br />
            We ensure the security and safety of our passengers, on-road as well as when it is parked.
            <br /><br />
            Make sure that the driver of our cab isn’t on the phone all the time. Also see if they are not misbehaving with the passenger. If there are any conflicts at the end of the day, we can view video recordings to ensure that nothing of that sort happens ever again.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <video width="100%" autoPlay loop muted>
            <source src={`${webUrl}/images/ec1.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Why Electric BYD E6 is a great option?</Typography>
          <Typography textAlign="left" variant="body1">
            <br />
            The BYD E6 is an all-electric vehicle that produces zero emissions, making it an eco-friendly and sustainable transportation option. It has a range of up to 520 km on a single charge, which makes it suitable for pickup or drop to Mumbai Airport from Pune.
            <br /><br />
            Additionally, the BYD E6 has a spacious interior that can comfortably seat up to five passengers. It also features advanced safety features parking sensors, and a rearview camera.
            <br /><br />
            Overall, the BYD E6 is a great option for those looking for an eco-friendly, high-tech electric vehicle with advanced safety features and a spacious interior.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <img
            src={`${webUrl}/images/istockphoto-1406257864-1024x1024.jpg?text=Slide+3`}
            alt="BYD E6"
            style={{ width: '100%', height: 'auto', borderRadius: '7px' }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Mumbai Airport to Pune Cab | Pune to Mumbai Airport Drop | MPV Innova/BYD E6 ₹3960 | Lowest Price Guarantee</Typography>
          <Typography textAlign="left" variant="body1">
            <br />
            Looking for a hassle-free and eco-friendly ride from Pune to Mumbai Airport? Look no further than Green Rides Pune! Our green cabs are the perfect way to travel with a clean conscience.
            <br /><br />
            Booking your ride with Green Rides Pune is easy and convenient. You can book your cab using WhatsApp or online, and we'll take care of the rest. Our expert drivers know the best routes to get you to the airport on time, so you can sit back and relax on your journey.
            <br /><br />
            Don't settle for just any cab service – choose the experts in airport cabs. Book your ride with Green Rides Pune today!
          </Typography>
          <WhatsAppButton></WhatsAppButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <video width="100%" autoPlay loop muted>
            <source src={`${webUrl}/images/ec3.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="h5">Why Green Rides Pune?</Typography>
          <Typography textAlign="left" variant="body1">
            <br />
            We ensure timely pickup and drop-off. We ensure that the cab arrives at the pickup location on time and that the customer is dropped off at the airport with enough time to spare for check-in and security clearance.
            <br /><br />
            Lowest Fare For Extra Large Car ₹3960
            <br /><br />
            Our Customer gives us 5 start rating always. Please see google reviews before booking our ride.
            <br /><br />
            We maintain a fleet of clean and well-maintained vehicles.
            <br /><br />
            We provide courteous and knowledgeable drivers.
            <br /><br />
            We offer competitive pricing.
            <br /><br />
            We provide online booking and payment options: Our customers prefer to book their rides online and pay electronically UPI (GPay, Paytm etc).
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <img
            src={image4}
            alt="BYD E6 interior"
            style={{ width: '100%', height: 'auto', borderRadius: '7px' }}
          />
        </Grid>
      </Grid >
    </>
  );
};

export default Home;
