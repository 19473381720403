import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Typography, Divider, Alert, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import '../styles/commonStyle.css';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { RequestBookingService } from "../components/services/RequestBookingService"
import NewBookingForm2 from './NewBookingForm2';
import NewBookingForm3 from './NewBookingForm3';
import CarouselBooking from '../components/CarouselBooking';
import { AppContext } from '../components/services/AppContext'
import { handleAutocompletePlaceService } from "../components/services/handleAutocompletePlaceService"


const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
//const googleApi = "AIzaSyBP3vgSopTFtFYGDGpM3ojSIAA-pzjAnaw"
const libraries: ("places")[] = ["places"];

interface FormValues {
  name: string;
  phone: string;
  distance: string;
  source: string;
  destination: string;
  bookingDate: string;
  bookingTime: string;
  returnRide: boolean,
  numberOfPassengers: number,
  numberOfBigBags: number,
}

const NewBookingPage: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApi, // Use environment variable for API key
    libraries: libraries
  });

  AppContext.grpLastMinuteBooking = false

  const theme = useTheme();

  const [booking] = useState(RequestBookingService.getRequestBooking())
  const [currentStep, setCurrentStep] = useState(0);
  const [source, setSource] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [sourceAutocomplete, setSourceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [destinationAutocomplete, setDestinationAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  //const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);

  const [isReturnRide, setIsReturnRide] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // console.log(`process.env.NODE_ENV ${JSON.stringify(process.env)}`);
  // console.log(`process.env.REACT_APP_BACKEND_URL ${process.env.REACT_APP_BACKEND_URL}`);

  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    phone: '',
    distance: "0",
    source: '',
    destination: '',
    bookingDate: '',
    bookingTime: '',
    returnRide: false,
    numberOfPassengers: 0,
    numberOfBigBags: 0
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const setRequestBookingSource = (place: google.maps.places.PlaceResult) => {
    RequestBookingService.setSource(place)
    const address = place.formatted_address
    if (address) {
      setSource(address)
      setFormValues({ ...formValues, source: address });
    }
  }

  const setRequestBookingDestination = (place: google.maps.places.PlaceResult) => {
    RequestBookingService.setDestination(place)
    const address = place.formatted_address
    if (address) {
      setDestination(address)
      setFormValues({ ...formValues, destination: address });
    }
  }

  const handleSourcePlaceChanged = async () => {
    if (!sourceAutocomplete) {
      console.error("sourceAutocomplete is not set yet.");
      return;
    }
    handleAutocompletePlaceService(sourceAutocomplete, setRequestBookingSource);

  }
  const handleDestinationPlaceChanged = async () => {
    if (!destinationAutocomplete) {
      console.error("destinationAutocomplete is not set yet.");
      return;
    }
    handleAutocompletePlaceService(destinationAutocomplete, setRequestBookingDestination);

  }
  const handleNext = async () => {
    if (!RequestBookingService.getSource()) {
      setSnackbarMessage("Please select your pick up address");
      setSnackbarOpen(true);
      return
    }
    if (!RequestBookingService.getDestination()) {
      setSnackbarMessage("Please select your drop address.");
      setSnackbarOpen(true);
      return
    }

    const pickUpLocation = RequestBookingService.getSource()
    const dropLocation = RequestBookingService.getDestination()

    if (pickUpLocation && dropLocation)
      await calculateDistance(pickUpLocation, dropLocation)

    await RequestBookingService.calculateFare()

    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const calculateDistance = async (origin: google.maps.places.PlaceResult, destination: google.maps.places.PlaceResult) => {
    // console.log("origin " + origin)
    // console.log("destination " + destination)
    if (!origin.geometry?.location || !destination.geometry?.location) {
      console.error("Invalid origin or destination. Make sure both have valid geometry.");
      return;
    }
    const originLatLng = {
      lat: origin.geometry.location.lat(),
      lng: origin.geometry.location.lng(),
    };

    let newLat = destination.geometry.location.lat();
    let newLong = destination.geometry.location.lng();

    if(newLat === 19.0902177 && newLong === 72.86281199999999 )
    {
      newLat = 19.0974203;
      newLong = 72.87460759999999;
    }
    const destinationLatLng = {
      lat: newLat,
      lng: newLong,
    };
    console.log("Origin LatLng:", newLat);
    console.log("Destination LatLng:", newLong);


    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originLatLng,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING,      
      provideRouteAlternatives: true,
    });

    if (results.routes[0]) {

      const route = results.routes[0];
      //  console.log("route = " + JSON.stringify(route))
      const distanceInMeters = route.legs[0].distance?.value || 0; // value in meters
      let localdistance = Math.ceil((distanceInMeters / 1000))// Convert to kilometers
      const distanceInKm = localdistance; 
      // console.log("distance in Km "+distanceInKm)
      RequestBookingService.setDistance(localdistance);
      setDistance(localdistance.toString());
      setFormValues(prev => ({
        ...prev,
        distance: distanceInKm.toString(), // Convert distance to string

      }));
      setDuration(route.legs[0].duration?.text || '');
    }
  };

  const handleRideOptionClick = (option: boolean) => {
    setIsReturnRide(option);
    setFormValues({ ...formValues, returnRide: option })
    if (booking) {
      booking.booking.returnRide = option
    }

  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <CarouselBooking></CarouselBooking>
      <br></br>
      <Box sx={{
        border: "2px solid grey", // Border color (blue)
        boxShadow: 3, // MUI shadow levels (1-24)
        borderRadius: 2, // Rounded corners
        p: 2, // Padding
      }}>
        {currentStep === 0 && (<>

          <br></br>
          <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
            Booking Request
          </Typography>
          <Typography
            variant="h6">
            Pune to Mumbai / Mumbai - Pune Ride
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box component="form" noValidate autoComplete="off"
          >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box onClick={() => handleRideOptionClick(false)} sx={{
                  border: "2px solid grey",
                  boxShadow: 3,
                  borderRadius: 2,
                  p: 2,
                }}>
                  <Typography variant="h6" sx={{ color: !isReturnRide ? '#0D47A1' : '#000' }}>One Way Mumbai Drop/Pickup</Typography>
                  {!isReturnRide ? (
                    <CheckCircleIcon color="success" fontSize='large' />
                  ) : (
                      <CheckCircleOutlineIcon color="action" fontSize='large' />
                    )}
                  <Typography variant="body2" sx={{ color: 'text.secondary', }}>One way Pune/Mumbai Drop/Pickup</Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box onClick={() => handleRideOptionClick(true)} sx={{
                  border: "2px solid grey",
                  boxShadow: 3,
                  borderRadius: 2,
                  p: 2,
                }}>
                  <Typography variant="h6" sx={{ color: isReturnRide ? '#0D47A1' : '#000' }}>Pune to Mumbai & Return</Typography>

                  {isReturnRide ? (
                    <CheckCircleIcon color="success" fontSize='large' />
                  ) : (
                      <CheckCircleOutlineIcon color="action" fontSize='large' />
                    )}
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>Pune to Mumbai Ride & return same day</Typography>
                </Box>
              </Grid>
            </Grid>
            <br></br>
            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Autocomplete
                onLoad={(autocomplete) => {
                  // console.log("Autocomplete loaded:", autocomplete);
                  setSourceAutocomplete(autocomplete)
                }}
                onPlaceChanged={() => { handleSourcePlaceChanged() }}
              >
                <TextField
                  label="Your Pick up Address"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  variant="outlined"
                  autoComplete="off"
                  required
                  fullWidth
                  // inputProps={{
                  //   autoComplete: "new-password", // Ensures auto-suggestions are disabled
                  // }}
                  onFocus={(e) => {
                    e.target.setAttribute("autocomplete", "new-password");
                  }}
                  sx={{ bgcolor: 'white', borderRadius: 1, mb: 3 }}
                />
              </Autocomplete>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Autocomplete
                onLoad={(autocomplete) => setDestinationAutocomplete(autocomplete)}
                onPlaceChanged={handleDestinationPlaceChanged}
              >
                <TextField
                  label="Your Drop Address"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  variant="outlined"
                  autoComplete="off"
                  onFocus={(e) => {
                    e.target.setAttribute("autocomplete", "new-password");
                  }}
                  required
                  fullWidth
                  sx={{ bgcolor: 'white', borderRadius: 1, mb: 3 }}
                />
              </Autocomplete>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Box position="relative" width="100%">

                <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  sx={{
                    '& .MuiSnackbarContent-root': {
                      maxWidth: '400px',
                      width: '100%',
                    },
                    position: 'absolute',
                    bottom: '60px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '90%',
                      width: '90%',
                    },
                  }}

                  aria-live="assertive"
                >
                  <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
                </Snackbar>

                <br></br>
                <Button variant="contained" color="primary" onClick={handleNext}
                  className='primary-button'
                >
                  NEXT
                </Button>
              </Box>
            </Grid>
            <br></br>
          </Box>
        </>

        )
        }

        {
          currentStep === 1 && (
            <>
              <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
                Booking Request
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <NewBookingForm2
                formValues={formValues}
                handleInputChange={handleInputChange}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            </>
          )
        }
        {
          currentStep === 2 && (
            <>
              <NewBookingForm3
                formValues={formValues}
                handleBack={handleBack}
              />
            </>
          )
        }

      </Box >
    </>
  );
};

export default NewBookingPage;
