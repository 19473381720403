import React from 'react';
import { useNavigate } from "react-router-dom";


interface ClickImageProps {
  imageUrl: string;
  label: string;
  redirectUrl: string;
}

const ClickImage: React.FC<ClickImageProps> = ({ imageUrl, label, redirectUrl }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(redirectUrl);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <img
        src={imageUrl}
        alt={label}        
        style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
        onClick={handleClick}
        loading='lazy'
      />
    </div>
  );
};

export default ClickImage;
