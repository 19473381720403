// src/pages/About.tsx
import { Button, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid2';
import CarouselBusiness from '../components/CarouselBusiness';

const GrpBusinessUser: React.FC = () => {

  return (
    <div>
      <CarouselBusiness></CarouselBusiness>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Corporate/Business Travel Management - Made Easy!
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h6" gutterBottom>
            Easy to use Booking App
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            Book a ride in just couple of clicks.
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            Resechule your in just 2 clicks
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            Tracking your Rides
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            Candendar View for easy to View and booking a new ride.
          </Typography>
        </Grid>
        <br />
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
        <Typography textAlign="left" variant="h4" gutterBottom>
            Weekly or monthly invoices
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            We can issue weekly or monthly invoices.
          </Typography>
        </Grid>
        <br />
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h4" gutterBottom>
            Download Business/Corporate User Brochure
          </Typography>
          <Typography textAlign="left" variant="h5" gutterBottom>
            <Button
              variant="contained"
              color="primary"
              href="/pdf/GRP-Business-Brochure.pdf"
              download="GRP-Business-Brochure.pdf" // Download attribute
              sx={{
                textTransform: "none",
                padding: "10px 20px",
                fontSize: "20px",
                WebkitTextFillColor: 'white',
              }}
            >
              Download Business/Corporate User Brochure
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default GrpBusinessUser;
