import { Booking } from "../../../models/booking";



export const carColor = (booking: Booking) => {
  if (booking.status === 'CANCELLED')
    return 'grey';
  if (booking.fromCity.toLowerCase().startsWith('pune'))
    return 'green';
  return 'blue';
};

export const statusColor = (booking: Booking) => {
  if (booking.status === 'NEW')
    return 'orange';
  if (booking.status === 'CANCELLED')
    return 'red';
  return 'green';
};

export const confirmationColor = (booking: Booking) => {
  if (booking.notification.confirmationMsg)
    return 'green';
  return 'red';
};

export const driverMsgColor = (booking: Booking) => {
  if (booking.notification.driverMsg)
    return 'green';
  return 'red';
};

export const cabDetailsColor = (booking: Booking) => {
  if (booking.notification.cabDetailsMsg)
    return 'green';
  return 'red';
};

export const paymentStatusColor = (booking: Booking) => {
  if (booking.status === 'CANCELLED')
    return 'grey';

  if (booking.payment.status === 'NOT_PAID')
    return 'red';

  if (booking.fromCity.toLowerCase() === 'pune') { return 'green'; }

  return 'blue';
};


export const travelDateValidation = (booking: Booking) => {
  if (booking.bookingDate === '' || booking.bookingDate === 'SELECT TRAVEL DATE')
    return 'Please select Date and time of travel!';
  return '';
};


export const emptyFormValues = () => {
  // const emptyFormValues = {
  //   name: '',
  //   phone: '',
  //   distance: "0",
  //   source: '',
  //   destination: '',
  //   bookingDate: '',
  //   bookingTime: '',
  //   returnRide: false,
  //   numberOfPassengers: 0,
  //   numberOfBigBags: 0

  // }


}

// export const emptyBooking = (): Booking => {
//   const emptyBooking: Booking = {
//     _id:"",
//     userId: '',
//     name: '',
//     bookingId: '',
//     phone: '',
//     createdDate: '',
//     bookingDate: '',
//     fromCity: '',
//     toCity: '',
//     fromAddress: '',
//     toAddress: '',
//     returnRide: false,
//     carId: '',
//     driverId: '',
//     partnerId: '',
//     payment: {
//       paymentId: "000000",
//       paymentType: "-",
//       baseFare: 0,
//       gstFare: 0,
//       toll: 0,
//       parking: 0,
//       waitingCharges: 0,
//       extraKmCharges: 0,
//       totalFare: 0,
//       extraKms: 0,
//       waitingTime: 0,
//       created: '',
//       status: '',
//     },
//     ride: {
//       startTime: '',
//       endTime: '',
//       rideOtp: '',
//       driverRating:"0",
//       customerRating:"0",
//       commentByCustomer:"",
//       commentByDriver:''
//     },
//     notification: {
//       confirmationMsg: true,
//       cabDetailsMsg: true,
//       driverMsg: true
//     },
//     status: '',
//   }
//   return emptyBooking;
// }
