import { AppContext } from './AppContext';
import { calculatePayment } from './BookingService';
import { RequestBooking } from './../models/RequestBooking';


export class RequestBookingService {
    static requestBooking: RequestBooking;

    static getRequestBooking(): RequestBooking {
        if (!this.requestBooking) {
            this.set();
        }
        return this.requestBooking;

    }

    static setRequestBooking(request: RequestBooking) {
        this.requestBooking = request

    }

    static setSource(source: google.maps.places.PlaceResult | null) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.source = source;
    }

    static setDestination(destination: google.maps.places.PlaceResult | null) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.destination = destination;
    }

    static getSource(): google.maps.places.PlaceResult | null {
        return this.requestBooking?.source || null;
    }

    static getDestination(): google.maps.places.PlaceResult | null {
        return this.requestBooking?.destination || null;
    }


    static getSourceFormattedAddress(): string {
        return this.requestBooking?.source?.formatted_address || "";
    }

    static getDestinationFormattedAddress(): string {
        return this.requestBooking?.destination?.formatted_address || "";
    }
    static setBookingDate(bookingDate: string) {
        this.requestBooking.bookingDate = bookingDate;
    }

    static setbookingTime(bookingTime: string) {
        this.requestBooking.bookingTime = bookingTime;
    }

    static setDistance(distance: number) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.distance = distance;
        this.calculateFare();
    }
    static getNewBookingId(length: number = 6): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    }

    static getCityFromAddress(address: string): string {

        if (address.toLocaleLowerCase().includes('mumbai'))
            return "Mumbai"

        return "Pune"

    }
    static getSelectedTolls() {
        if (this.requestBooking.booking.payment.toll === 320)
            return 'ExpressWay:320';
        if (this.requestBooking.booking.payment.toll === 365)
            return 'ExpressWay:320, VashiToll:45';
        if (this.requestBooking.booking.payment.toll === 640)
            return 'ExpressWay:320';

    }

    static async calculateDistance() {
        try {
            const origin = RequestBookingService.getSource();
            const destination = RequestBookingService.getDestination();

            const directionsService = new google.maps.DirectionsService();
            if (origin && destination) {
                const results = await directionsService.route({
                    origin: origin.formatted_address || origin.geometry?.location || '',
                    destination: destination.formatted_address || destination.geometry?.location || '',
                    travelMode: google.maps.TravelMode.DRIVING
                });

                if (results.routes.length > 0 && results.routes[0].legs.length > 0) {
                    const route = results.routes[0];
                    const distanceInMeters = route.legs[0].distance?.value || 0;
                    const distanceInKm = Math.ceil(distanceInMeters / 1000);
                    RequestBookingService.setDistance(distanceInKm);
                }
            } else {
                console.log("No valid routes found.");
            }
        } catch (error) {
            console.error("Error calculating distance:", error);
        }
    }


    static async calculateFare() {
        //  console.log("called")
        // console.log("AppContext.grpLastMinuteBooking " + AppContext.grpLastMinuteBooking)
        if (this.requestBooking.source?.formatted_address)
            this.requestBooking.booking.fromAddress = this.requestBooking.source?.formatted_address
        if (this.requestBooking.destination?.formatted_address)
            this.requestBooking.booking.toAddress = this.requestBooking.destination?.formatted_address

        this.requestBooking.booking.totalDistance = this.requestBooking.distance
        if (AppContext.grpLastMinuteBooking) {
            this.requestBooking.booking.status = "grpLastMinuteBooking"
        }
        else{
            this.requestBooking.booking.status = "grpOnlineBooking"
        }

        //  console.log("====== " + JSON.stringify(this.requestBooking.booking))
        const calFare = await calculatePayment(this.requestBooking.booking)
        //  console.log("p====== " + JSON.stringify(calFare))


        if (calFare) {
            this.requestBooking.booking.payment.baseFare = calFare.baseFare;
            this.requestBooking.booking.payment.toll = calFare.toll
            this.requestBooking.booking.payment.parking = calFare.parking
            this.requestBooking.booking.payment.extraKms = calFare.extraKms
            this.requestBooking.booking.payment.extraKmCharges = calFare.extraKmCharges
            this.requestBooking.booking.payment.gstFare = calFare.gstFare
            this.requestBooking.booking.payment.totalFare = calFare.totalFare
            this.requestBooking.booking.payment.extraPickorDrop = calFare.extraPickorDrop
            this.requestBooking.booking.payment.extraPickorDropCharges = calFare.extraPickorDropCharges
            this.requestBooking.booking.payment.discount = calFare.discount
        }

    }

    static set() {
        if (!this.requestBooking) {
            // Initialize the requestBooking if it doesn't exist
            this.requestBooking = {
                booking: {
                    _id: '',
                    userId: '',
                    bookingId: '',
                    phone: '',
                    name: '',
                    fromCity: '',
                    toCity: '',
                    bookingDate: '',
                    createdDate: new Date().toISOString(), // or set as needed
                    fromAddress: '',
                    toAddress: '',
                    selectedTolls: '',
                    totalDistance: 0,
                    returnRide: false,
                    numberOfBigBags: 0,
                    numberOfPassengers: 0,
                    notification: {
                        confirmationMsg: false,
                        cabDetailsMsg: false,
                        driverMsg: false,
                    },
                    carId: '',
                    driverId: '',
                    partnerId: '',
                    referenceId: '',
                    corporateId: '',
                    couponCode: '',
                    payment: {
                        paymentId: '',
                        paymentType: '',
                        AdvancePayment: 0,
                        baseFare: 0,
                        gstFare: 0,
                        toll: 0,
                        parking: 0,
                        waitingCharges: 0,
                        extraKmCharges: 0,
                        totalFare: 0,
                        extraKms: 0,
                        waitingTime: 0,
                        carrierCharges: 0,
                        extraPickorDrop: 0,
                        extraPickorDropCharges: 0,
                        discount: 0,
                        created: new Date().toISOString(),
                        status: 'NOT_PAID',
                    },
                    ride: {
                        startTime: 'NA',
                        endTime: 'NA',
                        rideOtp: '0000',
                        customerRating: '0',
                        driverRating: '0',
                        commentByCustomer: 'NA',
                        commentByDriver: 'NA',
                    },
                    status: 'NEW',
                },
                source: null,
                destination: null,
                distance: 0,
                bookingDate: '',
                bookingTime: '',
            };
        }
    }

    static setToInitialState() {    
            // Initialize the requestBooking 
            this.requestBooking = {
                booking: {
                    _id: '',
                    userId: '',
                    bookingId: '',
                    phone: '',
                    name: '',
                    fromCity: '',
                    toCity: '',
                    bookingDate: '',
                    createdDate: new Date().toISOString(), // or set as needed
                    fromAddress: '',
                    toAddress: '',
                    selectedTolls: '',
                    totalDistance: 0,
                    returnRide: false,
                    numberOfBigBags: 0,
                    numberOfPassengers: 0,
                    notification: {
                        confirmationMsg: false,
                        cabDetailsMsg: false,
                        driverMsg: false,
                    },
                    carId: '',
                    driverId: '',
                    partnerId: '',
                    referenceId: '',
                    corporateId: '',
                    couponCode: '',
                    payment: {
                        paymentId: '',
                        paymentType: '',
                        AdvancePayment: 0,
                        baseFare: 0,
                        gstFare: 0,
                        toll: 0,
                        parking: 0,
                        waitingCharges: 0,
                        extraKmCharges: 0,
                        totalFare: 0,
                        extraKms: 0,
                        waitingTime: 0,
                        carrierCharges: 0,
                        extraPickorDrop: 0,
                        extraPickorDropCharges: 0,
                        discount: 0,
                        created: new Date().toISOString(),
                        status: 'NOT_PAID',
                    },
                    ride: {
                        startTime: 'NA',
                        endTime: 'NA',
                        rideOtp: '0000',
                        customerRating: '0',
                        driverRating: '0',
                        commentByCustomer: 'NA',
                        commentByDriver: 'NA',
                    },
                    status: 'NEW',
                },
                source: null,
                destination: null,
                distance: 0,
                bookingDate: '',
                bookingTime: '',
            };
    
    }








}
