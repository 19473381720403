// src/AppInsights.ts
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "b4f54fc5-7ae6-4fb8-a324-76c09df73890", // Replace with your Instrumentation Key        
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope) => {
    envelope.data = {
      ...envelope.data,
      environment: "Dev", // Add environment as a custom property
      appName: "GrpWeb", // Add app name as a custom property
    };
  });

export { appInsights, reactPlugin };
