// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselWhy from '../components/CarouselWhy';
import GrpIconButton from '../components/GrpIconButton';


const WhyGrp: React.FC = () => {

  return (
    <div>
      <CarouselWhy></CarouselWhy>
      <br></br>
      <Typography textAlign="left" variant="h4">Why Green Rides Pune?</Typography>
      <Typography textAlign="left" variant="body1">
        <br />
        We ensure timely pickup and drop-off. We ensure that the cab arrives at the pickup location on time and that the customer is dropped off at the airport with enough time to spare for check-in and security clearance.
        <br /><br />
        Lowest Fare For Extra Large Car ₹3760
        <br /><br />
        Our Customer gives us 5 star rating always. Please see google reviews before booking our ride.
        <br /><br />
        We maintain a fleet of clean and well-maintained vehicles.
        <br /><br />
        We provide courteous, experienced and knowledgeable drivers.
        <br /><br />
        We offer very competitive pricing.
        <br /><br />
        We provide online booking and payment options: Our customers prefer to book their rides online and pay electronically UPI (GPay, Paytm etc).
      </Typography>
      <br />
      <GrpIconButton name={'BOOK RIDE NOW'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
      <br /><br />
    </div>
  );
};

export default WhyGrp;
