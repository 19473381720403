import { } from './queries';
//import { AppContext } from './AppContext';
import { CREATE_NEW_BOOKING, } from './mutation';
import { GET_BOOKINGS_BY_USERID, CALCULATE_FARE, GET_YESTERDAY_ONWARD_BOOKINGS, SEND_BOOKINGMSG_USER, SEND_BOOKINGMSG_DRIVER } from './queries';
import client from "./apolloClient";
import { Booking, BookingInput, Payment } from "../models/booking";
import { AppContext } from './AppContext'



export const createBooking = async (newBooking: BookingInput): Promise<Booking | null> => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_NEW_BOOKING,
      variables: { newBooking },
      context: {
        headers: {
          // Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      }
    });
    // refreshAppContext(data?.createBooking);
    return data?.createBooking;
  } catch (error) {
    // console.error('Error fetching create booking:', error);
    console.log("Error fetching create booking:" + error)
    return null;
  }
}

export const getBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | null> => {
  if (AppContext.bookings && AppContext.bookings.length > 0) {
    return AppContext.bookings;
  }
  if (userId === undefined) {
    return null;
  }
  try {
    const { data } = await client.query({
      query: GET_BOOKINGS_BY_USERID,
      variables: { userId },
      context: {
        headers: {
          // Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      }
    });
    //await refreshAppContext(data?.getBookingsByUserId);
    return data?.getBookingsByUserId;
  } catch (error) {
    console.log('Error fetching getBookingsByUserId:', error);
    return null;
  }
}
export const getYesterDayOnwardBookings = async (): Promise<Booking[] | null> => {

  try {
    const { data } = await client.query({
      query: GET_YESTERDAY_ONWARD_BOOKINGS,
      variables: {},
    });
    return data?.getYesterdayOnwardsBookings;
  } catch (error) {
    console.log('Error fetching getYesterdayOnwardsBookings:', error);
    return null;
  }
}

export const getOngoingBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {

  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => item.status === 'STARTED').sort((a, b) => { return a.bookingDate.localeCompare(b.bookingDate) });
  return filtered;
}

export const getYerterdayOnwardsOngoingBookings = async (): Promise<Booking[] | undefined> => {

  const bookings = await getYesterDayOnwardBookings();

  const filtered = bookings?.filter(item => item.status === 'STARTED').sort((a, b) => { return a.bookingDate.localeCompare(b.bookingDate) });

  return filtered;
};


export const getNextBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {
  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => (item.status === 'CONFIRMED' || item.status === 'STARTED' || item.status === 'NEW')).sort((a, b) => { return a.bookingDate.localeCompare(b.bookingDate) });
  return filtered;
}

export const getFinishedBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {
  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => item.status === 'FINISHED' || item.status === 'CANCELLED').sort((a, b) => { return b.bookingDate.localeCompare(a.bookingDate) });
  return filtered;
}


export const calculatePayment = async (booking: Booking): Promise<Payment | null> => {
  try {

    // console.log(booking.payment);
    // console.log("in calculatePayment = " + JSON.stringify(booking))
    const { data, errors } = await client.query({
      query: CALCULATE_FARE,
      variables: { booking },
      context: {
        headers: {
          //  Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      },

    });
    if (errors) {
      console.log(errors)
    }
    return data?.calculateFare;
  } catch (error) {
    console.log('Error fetching calculate Payment :', error);

    return null;
  }
}
function removeTypename(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item));
  }

  if (obj && typeof obj === 'object') {
    const { __typename, _id, ...rest } = obj; // Remove both __typename and _id

    return Object.keys(rest).reduce((acc: { [key: string]: any }, key: string) => {
      acc[key] = removeTypename(rest[key]);
      return acc;
    }, {});
  }
  return obj;
}

export const sendBookingMsgToUser = async (booking: Booking, ): Promise<Boolean | null> => {

  const newbooking = removeTypename(booking)
  try {
    const { data } = await client.query({
      query: SEND_BOOKINGMSG_USER,
      variables: { booking: newbooking },
    });

    return data?.sendBookingMsgToUser;
  } catch (error) {
    console.log('Error fetching sendBookingMsgTo user:', error);
    return null;
  }
}

export const sendBookingMsgToDriver = async (booking: Booking, driverId: string, ): Promise<Boolean | null> => {
  const newbooking = removeTypename(booking)
  // console.log(JSON.stringify(newbooking), +"   " + driverId)
  try {
    const { data } = await client.query({
      query: SEND_BOOKINGMSG_DRIVER,
      variables: { booking: newbooking, driverId },
    });

    return data?.sendBookingMsgToDriver;
  } catch (error) {
    console.log('Error fetching sendBookingMsg to driver:', error);
    return null;
  }
}