
import { gql } from '@apollo/client';

export const CREATE_NEW_USER = gql`
mutation CreateUser($newUser: UserInput!) {
  createUser(newUser: $newUser) {
    _id
    name
    phone
    email
    status
  }
}
`
export const CREATE_NEW_USERDETAILS = gql`
mutation CreateUserDetails($newUserDetails: UserDetailsInput!) {
  createUserDetails(newUserDetails: $newUserDetails) {
    _id
    userId
    gstNumber
    billingAddress
    savedAddresses {
      _id
      placeId
      description
      latitude
      longitude
    }
  }
}

`
export const ADD_TO_SAVED_ADDRESS = gql`
mutation AddToSavedAddress($userId: String!, $address: savedAddressInput!) {
  addToSavedAddress(userId: $userId, address: $address) {
    _id
    userId
    gstNumber
    billingAddress
    savedAddresses {
      _id
      placeId
      description
      latitude
      longitude
    }
  }
}
`;

export const UPDATE_TOTAL_RIDES = gql`
mutation UpdateUserTotalRides($Id: String) {
  updateUserTotalRides(Id: $Id) {
    _id
    name
    phone
    mpin
    email
    userRating
    userBrand
    totalRides
    status
  }
}
`

export const CREATE_NEW_BOOKING = gql`
mutation CreateBooking($newBooking: BookingInput!) {
  createBooking(newBooking: $newBooking) {
    _id
    userId
    name
    bookingId
    phone
    createdDate
    bookingDate
    fromCity
    toCity
    fromAddress
    toAddress
    selectedTolls
    totalDistance
    returnRide
    numberOfPassengers
    numberOfBigBags
    carId
    driverId
    referenceId
    couponCode
    ride {
      startTime
      endTime
      rideOtp
      customerRating
      driverRating
      commentByCustomer
      commentByDriver
    }
    payment {
      paymentId
      paymentType
      AdvancePayment
      baseFare
      gstFare
      toll
      parking
      waitingCharges
      extraKmCharges
      totalFare
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
      created
      status
    }
    partnerId
    notification {
      confirmationMsg
      cabDetailsMsg
      driverMsg
    }
    status
  }
}
`


export const CREATE_INVOICE = gql`
mutation CreateInvoice($newInvoice: InvoiceInput!) {
  createInvoice(newInvoice: $newInvoice) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
    payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`

export const UPDATE_INVOICE = gql`

mutation UpdateInvoice($newInvoice: InvoiceInput!) {
  updateInvoice(newInvoice: $newInvoice) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
     payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`
export const UPDATE_INVOICE_NUMBER= gql`
mutation UpdateInvoiceNumber {
  updateInvoiceNumber {
    toll {
      tollName
      tollPrice
    }
    lastInvoiceNumber
  }
}
`
export const UPDATE_CAR_AVAILABILITY= gql`
mutation UpdateCarAvailability($carId: String, $fieldName: String, $fieldValue: String) {
  updateCarAvailability(carId: $carId, fieldName: $fieldName, fieldValue: $fieldValue) {
    _id
    carId
    driverId
    carType
    carRoute
    carLocation
    carTo
    fare
    availableIn
    availabilitySetTime
  }
}
`

