import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';

const ads1 = "/images/SQ_business/SQ_business-1.jpg";
const ads2 = "/images/SQ_business/SQ_business-2.jpg";
const ads3 = "/images/SQ_business/SQ_business-3.jpg";

const CarouselBusiness: React.FC = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box sx={{ width: '98%', margin: '0 auto', padding: '10px 2px 2px 2px' }}>
      <Slider {...settings}>
        <div>
          <img
            src={ads1}
            alt="BYD E6 - Premium Electric MPV"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads2}
            alt="BYD E6 Photos"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads3}
            alt="Electric &amp; Premium - BYD E6"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
      </Slider>
    </Box>
  );
};

export default CarouselBusiness;
