import { Box, Card, CardContent, Typography } from '@mui/material';

import { mdiBatteryCharging } from '@mdi/js';
import { mdiSeatLegroomNormal  } from '@mdi/js';
import { mdiSprout } from '@mdi/js';
import { mdiCarElectric  } from '@mdi/js';

import Icon from '@mdi/react';

interface CustomCardBYDProps {
    title: string;
    description: string;
}

const CustomCardBYD = ({ title, description }: CustomCardBYDProps) => {
    return (
        <Card
            sx={{
                maxWidth: 400,
                margin: 'auto',
                mt: 5,
                background: 'linear-gradient(135deg, rgb(0, 43, 214),rgb(96, 121, 222))',
                color: 'white',
                borderRadius: '16px',
                boxShadow: 3,
                textAlign: 'center',
            }}
        >
            <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h6" align='left'>{description}
                    <br></br><br></br><Icon path={mdiBatteryCharging} size={1} /> Long Battery Life
                    <br></br><Icon path={mdiSeatLegroomNormal } size={1} />  Spacious Design 
                    <br></br><Icon path={mdiSprout} size={1} /> Eco-Friendly Option
                    <br></br><Icon path={mdiCarElectric} size={1} /> Comfort & Technology
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CustomCardBYD;
