import { AppContext } from './AppContext';
import { savedAddress, UserDetailsInput } from './../models/UserDetails';
// import { UpdateUserDetails } from '../models/UpdateUserDetails';
import {  ADD_TO_SAVED_ADDRESS, CREATE_NEW_USERDETAILS } from './mutation';
import { UserDetails } from "../models/UserDetails";
import { GET_USERDETAILS_BY_USERID, GET_SAVED_ADDRESS_BY_DESCRIPTION } from './queries';
import client from './apolloClient';



export const createNewUserDetails = async (newUserDetails: UserDetailsInput): Promise<UserDetails> => {
    try {
        const { data } = await client.mutate({
            mutation: CREATE_NEW_USERDETAILS,
            variables: { newUserDetails },
            context: {
                headers: {
                    Authorization:AppContext.loggedAuthToken? `Bearer ${AppContext.loggedAuthToken}` : '',
                },
            }
        });
        return data?.createUserDetails;
    } catch (error) {
        console.log('Error fetching new userDetails:', error);
        return { userId: "", billingAddress: "", gstNumber: "", savedAddresses: [{ placeId: "", description: "", latitude: 0, longitude: 0 }] };
    }
}
export const getUserDetailsByUserid = async (userId: string): Promise<UserDetails | null> => {
    try {
        const { data } = await client.query({
            query: GET_USERDETAILS_BY_USERID,
            variables: { userId },
            context: {
                headers: {
                    Authorization:AppContext.loggedAuthToken? `Bearer ${AppContext.loggedAuthToken}` : '',
                },
            }
        });
        return data?.getUserDetailsByUserId;

    } catch (error) {
        //  console.error('Error fetching userdetails:', error);
        console.log(`in service Userdetails with userId ${userId} not found`)
        return null;
    }
}


export const addToSaveAddress = async (userId: string, address: savedAddress): Promise<UserDetails | null> => {
    try {
        const { data } = await client.mutate({
            mutation: ADD_TO_SAVED_ADDRESS,
            variables: { userId, address },
            context: {
                headers: {
                    Authorization:AppContext.loggedAuthToken? `Bearer ${AppContext.loggedAuthToken}` : '',
                },
            }
        });
        
        return data?.addToSavedAddress;
    } catch (error) {
        console.log('Error fetching addToSavedAddress:', error);
        return null;
    }
}

export const getSavedAddressbyDescription = async (userId: string, description: string|undefined): Promise<UserDetails | null> => {
    try {
        const { data } = await client.query({
            query: GET_SAVED_ADDRESS_BY_DESCRIPTION,
            variables: { userId, description },
            context: {
                headers: {
                    Authorization:AppContext.loggedAuthToken? `Bearer ${AppContext.loggedAuthToken}` : '',
                },
            }
        });
        return data?.getSaveAddressByDescription;

    } catch (error) {
        console.log('Error fetching getSavedAddressByDescription:', error);
        return null;
    }
}


