import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { TextField } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { AppContext } from '../../services/AppContext';


type CountryPickerProps = {
    phoneText: string;
    onChange: (phone: string) => void
}

const CountryCodePicker: React.FC<CountryPickerProps> = ({ phoneText, onChange }: CountryPickerProps) => {
    const [selectedCode, setSelectedCode] = useState<string>('91');
    const [phone, setPhone] = useState<string>(phoneText);
    //  const { updatePhone } = useContext(AppContext);

    useEffect(() => {
        AppContext.phone = `(+${selectedCode})${phone}`
    }, [selectedCode, phone,]);

    const handlePhoneChange = (value: string) => {
        //const code = value.substring(1, value.length); // Extract code from value
        setSelectedCode(value);
        // console.log("Selected Code: " + value); // Log
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        // onChange(event.target.value)
        //   console.log("changedPhone: " + event.target.value);
        const phoneWithCode = `(+${selectedCode})${phone}`

        onChange(event.target.value)

    };

    return (
        <>
            <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                <PhoneInput
                    country={selectedCode.toLowerCase()}
                    value={selectedCode}
                    countryCodeEditable={false}
                    onChange={handlePhoneChange}

                    containerStyle={{
                        width: '100%',
                        margin: '9px auto',
                        border: '1px solid #ccc',
                        borderRadius: '2px',
                    }}
                    inputStyle={{
                        width: '100%',
                        height: '54px',
                        textAlign: 'right',
                        paddingLeft: '10px',
                        paddingRight: '5px',
                        fontSize: '16px',
                        borderRadius: '1px',
                        border: 'none',
                    }}
                    buttonStyle={{
                        borderRadius: '2px 0 0 2px',
                        backgroundColor: '#f1f1f1',
                    }}
                    dropdownStyle={{
                        borderRadius: '1px',
                    }}
                />
            </Grid>
            <Grid size={{ xs: 9, sm: 9, md: 9 }}>
                <TextField
                    name="phoneNumber"
                    label="Your WhatsApp Number"
                    variant="outlined"
                    value={phone}
                    // disabled={phoneVerified}
                    fullWidth
                    onChange={handlePhoneInputChange}
                    sx={{ bgcolor: 'white', width: '98%', borderRadius: 1, mt: 1, mb: 0 }}
                />
            </Grid>
        </>

    );
};

export default CountryCodePicker;
