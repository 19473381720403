import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import CarouselUser from '../components/CarouselUser';

const GrpUser: React.FC = () => {

  // const webUrl = process.env.REACT_APP_URL;

  return (
    <div>
      <CarouselUser></CarouselUser>
      <br></br>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/*<Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            How to Download User App?
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid>
        <Divider></Divider>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Easy User Registration using WhatsApp. 
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid>
        <Divider></Divider>
         <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Easy to Book a ride
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid>
        <Divider></Divider>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Easy Fare Calculation with exact distance.
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid>
        <Divider></Divider>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Easy to Track your booking
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Easy to download Invoice after ride.
          </Typography>
          <Typography textAlign="left" variant="h6" gutterBottom>
            TBD
          </Typography>
        </Grid> */}
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5" gutterBottom>
            Download User Brochure PDF file.
          </Typography>
          <Typography textAlign="left" variant="h5" gutterBottom>
            <Button
              variant="contained"
              color="primary"
              href="/pdf/GRP-User-Brochure.pdf"
              download="GRP-User-Brochure.pdf" // Download attribute
              sx={{
                textTransform: "none",
                padding: "10px 20px",
                fontSize: "20px",
                WebkitTextFillColor: 'white',
              }}
            >
              Download User Brochure
            </Button>
          </Typography>

        </Grid>
      </Grid>
      <br></br>
    </div>
  );
};

export default GrpUser;
