const TERMINAL_2_ADDRESS = {
    address_components: [
      { long_name: "Terminal 2", short_name: "Terminal 2", types: ["premise"] },
      { long_name: "Navpada", short_name: "Navpada", types: ["sublocality_level_3", "sublocality", "political"] },
      { long_name: "Vile Parle East", short_name: "Vile Parle East", types: ["sublocality_level_2", "sublocality", "political"] },
      { long_name: "Vile Parle", short_name: "Vile Parle", types: ["sublocality_level_1", "sublocality", "political"] },
      { long_name: "Mumbai", short_name: "Mumbai", types: ["locality", "political"] },
      { long_name: "Mumbai Suburban", short_name: "Mumbai Suburban", types: ["administrative_area_level_3", "political"] },
      { long_name: "Konkan Division", short_name: "Konkan Division", types: ["administrative_area_level_2", "political"] },
      { long_name: "Maharashtra", short_name: "MH", types: ["administrative_area_level_1", "political"] },
      { long_name: "India", short_name: "IN", types: ["country", "political"] },
      { long_name: "400099", short_name: "400099", types: ["postal_code"] }
    ],
    name: "Terminal 2",
    formatted_address: "Terminal 2, Navpada, Vile Parle East, Vile Parle, Mumbai, Maharashtra 400099, India",
    location: { lat: 19.0974373, lng: 72.8745017 },
    place_id: "ChIJ8YKCtkLI5zsR8aQdh3KMDSA",
    url: "https://maps.google.com/?q=Terminal+2&ftid=0x3be7c842b68282f1:0x200d8c72871da4f1"
  };
  
  // Function to get the address
  export const getTerminalT2Address = () => TERMINAL_2_ADDRESS;
  