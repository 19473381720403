import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CarouselHome from '../components/CarouselHome';
import WhatsAppButtonLong from '../components/WhatsAppButtonLong';
import PhoneCallButton from '../components/PhoneCallButton';

const ContactUs: React.FC = () => {
  const webUrl = process.env.REACT_APP_URL;
  const backendurl = process.env.REACT_APP_BACKEND_URL;

  return (
    <div>
      <CarouselHome></CarouselHome>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="body1">
            GREEN RIDES PUNE, <br />
            201-Metropolis,<br />
            Balewadi High Street, <br />
            Balewadi, Pune - 411045, INDIA. <br />
            Number: +91-8600-57-2727  &nbsp; <PhoneCallButton></PhoneCallButton><br /> 
            Email: contact@greenrides.in<br />
          </Typography>
          <br></br>
          <WhatsAppButtonLong></WhatsAppButtonLong>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <a href="https://www.google.com/maps/place/Green+Rides+Pune/@18.5752004,73.7726852,15z/data=!4m6!3m5!1s0x3bc2b9b76defa29b:0x3763a144d474613a!8m2!3d18.574423!4d73.7748723!16s%2Fg%2F11stvh9b3j?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">
            <img
              src={`${webUrl}/images/Contact_us.jpg`}
              alt="Green Rides Pune"
              style={{ width: '100%', height: 'auto', border: '1px solid grey' }}
              loading='lazy'
            />
          </a>
        </Grid>
      </Grid>
      <br />
      <Typography textAlign="left" variant="h6" style={{ color: 'white' }} gutterBottom>
        {`${backendurl}`}
      </Typography>
    </div >
  );
};

export default ContactUs;

