import { Typography } from '@mui/material';
import React from 'react';


const GrpReferFriend: React.FC = () => {

  return (
    <div>
      <video width="100%" autoPlay loop muted controls>
        <source src={`/video/SQ_video-1-refer-a-friend.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Refer a Friend!
      </Typography>
      <Typography textAlign="left" variant="h6" gutterBottom>
        Get ₹400 off your next ride when you refer a friend! Your friend also gets ₹200 off.*
      </Typography>
      <Typography textAlign="left" variant="h6" gutterBottom>
        WhatsApp your friend's name & mobile number to 918600572727.
      </Typography>
      <br />
    </div>
  );
};

export default GrpReferFriend;
