import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect } from 'react';
import '../App.css';
import CarouselHome from '../components/CarouselHome';
import CarouselReview from '../components/CarouselReview';
import ClickImage from '../components/ClickImage';
import WhatsAppButtonLong from '../components/WhatsAppButtonLong';
import AutoCounter from '../components/AutoCounter';
import GrpIconButton from '../components/GrpIconButton';
import GrpButton from '../components/GrpButton';
import CustomCardPremiumService from '../components/CustomCardPremiumService';
import CustomCardBYD from '../components/CustomCardBYD';
import CustomCardSafety from '../components/CustomCardSafety';
import FloatingPhoneButton from '../components/FloatingPhoneButton';



const Home: React.FC = () => {
  return (
    <>
      <CarouselHome />
      <br />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5">Pune to Mumbai Airport Cab | Innova/BYD Oneway drop @ ₹3700</Typography>
          <Typography variant="body1" textAlign="left">
          <br />
            🚕 Green Rides Pune Cab – Your Eco-Friendly Pune - Mumbai Travel Partner!<br />
            <br />
            🌿 Planning a One-Way Trip from Pune to Mumbai Airport / Mumbai Airport to Pune ?<br />
            Travel in comfort with our Extra Large, Eco-Friendly Cabs – the perfect blend of convenience and sustainability.
            <br /><br />
            ✅ Hassle-Free Booking Process<br />
            Book your ride online in just a few clicks!<br />
            <br />
            ⏰ Reliable On-Time Pickup & Drop-Off<br />
            Reach your destination on time, every time!<br />
            <br />
            🌍 Go Green with Us<br />
            Our cabs run on clean energy sources, helping you reduce your carbon footprint.<br />
            <br />
            💺 Spacious & Comfortable Rides<br />
            Enjoy extra legroom and premium comfort on your journey.<br />
            <br />
            Choose Green Rides Pune Cab – where comfort meets sustainability!<br />
            <br />
            Book Now & Travel Smarter!<br />
            <br />
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <WhatsAppButtonLong></WhatsAppButtonLong>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpIconButton name={'Book a Mumbai Airport Ride'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpIconButton name={'Last Minute Mumbai Airport Ride'} redirectUrl={'/book-now'} ></GrpIconButton>
        </Grid>
        <br />

        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <ClickImage imageUrl={'/images/GrpImages/GrpImages-10.jpg'} label={'Why Green Rides'} redirectUrl={'why-grp'} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5">Why Green Rides Pune?</Typography>
          <Typography textAlign="left" variant="body1">
            We ensure timely pickup and drop-off. We ensure that the cab arrives at the pickup location on time and that the customer is dropped off at the airport with enough time to spare for check-in and security clearance.
            <br /><br />
            🌟 Why Choose Us for Your Airport Ride?<br />
            <br />
            🚖 Timely Pickup & Drop-Off<br />
            We guarantee on-time arrival at your pickup location and ensure you reach the airport with ample time for check-in and security clearance.
            <br /><br />
            💰 Lowest Fare for Extra Large Car – ₹3700<br />
            Enjoy the best fare without compromising on comfort.<br />
            <br />
            ⭐ 5-Star Rated Service<br />
            Our customers consistently give us 5-star ratings! Check our Google Reviews before booking.<br />
            <br />
            🧼 Clean & Well-Maintained Fleet<br />
            Travel in comfort with our spotless, well-maintained vehicles.<br />
            <br />
            👨‍✈️ Experienced & Courteous Drivers<br />
            Our drivers are professional, polite, and knowledgeable.<br />
            <br />
            💳 Easy Online Booking & Payment Options<br />
            Book your ride online and pay conveniently via Online Bank Transfer/ UPI (GPay, Paytm, etc.) / Cash<br />
            <br />
            Book Now and Experience a Hassle-Free Ride!<br />
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <CustomCardPremiumService title={'Premium Service'} description={'Fleet of Electric BYD E6 cars with experienced polite drivers.'}></CustomCardPremiumService>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <CustomCardBYD title={'Why BYD E6?'} description={'The BYD E6 is a popular EV known for commercial.'}></CustomCardBYD>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <CustomCardSafety title={'Extra Safety'} description={'For Women travelers using next-gen camera.'}></CustomCardSafety>
        </Grid>
        <br />
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <CarouselReview></CarouselReview>
          <br></br>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpButton name={'Safety Measures For Women Travelers'} redirectUrl={'/extra-safety'} ></GrpButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpButton name={'BYD E6 Details'} redirectUrl={'/why-byd'} ></GrpButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpIconButton name={'Book a Mumbai Airport Ride'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
        </Grid>
        <br />
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <ClickImage imageUrl={'/images/GrpImages/GrpImages-20.jpg'} label={'Book Online & Save 260 Off'} redirectUrl={'book-your-green-ride'} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography textAlign="left" variant="h5">Get ₹260 OFF on Your Booking!</Typography>
          <Typography textAlign="left" variant="body1">
            🎯 Exclusive Online Offer!<br></br>
            Book online and get ₹260 OFF on your booking.<br></br>
            <br></br>
            ✨ Limited Time Offer<br></br>
            💳 Instant Discount Applied<br></br>
            ⚡ Hassle-Free Online Booking<br></br>
            <br></br>
            Don't Miss Out – Book Now & Save ₹260!<br></br>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <GrpIconButton name={'Book a Mumbai Airport Ride'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
          <br></br><br></br>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography variant="h4" fontWeight="bold" color='green'>
            Pune - Mumbai Airport Rides
          </Typography>
          <br></br>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }} alignContent={'center'}>
          <AutoCounter targetNumber={5000} speed={50} increment={100} text="Pune - Mumbai Airport Rides" />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <AutoCounter targetNumber={1000000} speed={50} increment={20000} text="Green kilometers" extension="+" />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <AutoCounter targetNumber={200} speed={50} increment={4} text="CO2 saved by Green Rides " extension="+ tonnes" />
        </Grid>
        <br></br>
      </Grid >
    </>
  );
};

export default Home;
