import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButtonLong: React.FC = () => {
    const handleClick = () => {
        const phoneNumber = "+919028249977"; // Replace with actual number
        const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=Hi%2C+%0AI%27m+interested+in+booking+a+cab%2C%0AFrom%3A+PUNE+to+Mumbai+Airport+T2%0ADate%3A%3F%0ATime%3A%3F%0ANumber+of+Passengers%3A%3F+%0ANumber+of+Big+Bags%3A%3F%0APune+Pickup%2FDrop+Location%3A%3F&type=phone_number&app_absent=0`;
        window.open(url, '_blank');
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"                
                startIcon={<WhatsAppIcon />}
                onClick={handleClick}
                sx={{           
                    color: 'white',              // Text color
                    background: 'linear-gradient(45deg,rgb(5, 211, 15) 30%,rgb(85, 184, 79) 90%)',
                    '&:hover': {
                        backgroundColor: '#1ebe57', // Darker green on hover
                    },
                    textTransform: "none",
                    padding: "7px 14px",
                    fontSize: "16px",
                    WebkitTextFillColor: 'white',
                }}
            > Chat with Our Smart Assistant!
            </Button>
        </>
    );
};

export default WhatsAppButtonLong;
