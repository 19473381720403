import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { Container } from '@mui/material';
import StickyHeader from './components/StickyHeader';
import './App.css';
import Footer from './components/Footer';
import ContactUs from './pages/ContactUs';
import NewBookingForm from './pages/NewBookingForm';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ExtraSafetyMeasures from './pages/ExtraSafetyMeasures';
import GrpBusinessUser from './pages/GrpBusinessUser';
import GrpBusinessUserApp from './pages/GrpBusinessUserApp';
import GrpDriver from './pages/GrpDriver';
import GrpPartnerUserApp from './pages/GrpPartnerUserApp';
import WhyGrp from './pages/WhyGrp';
import GrpPartnerUser from './pages/GrpPartnerUser';
import GrpUser from './pages/GrpUser';
import GrpReferFriend from './pages/GrpReferFriend';
import GrpLastMinuteBooking from './pages/GrpLastMinuteBooking';
import WhyBYD from './pages/WhyBYD';
import { appInsights } from './AppInsights';
import GrpAppQRCodeUser from './pages/GrpAppQRCodeUser';
import GrpAppQRCodeDriver from './pages/GrpAppQRCodeDriver';
import GrpAppQRCodePartner from './pages/GrpAppQRCodePartner';
import GrpAppQRCodeBusiness from './pages/GrpAppQRCodeBusiness';
import GrpAppQRCode from './pages/GrpAppQRCode';


const App: React.FC = () => {

  const location = useLocation();

  useEffect(() => {
    const pageStartTime = window.performance.timing.navigationStart;
    const pageEndTime = window.performance.timing.domContentLoadedEventEnd;
    const pageLoadTime = pageEndTime - pageStartTime; // Time it takes to load the page

    // Track page view performance in Application Insights
    appInsights.trackPageViewPerformance({
      name: location.pathname,
      properties: {
        pageLoadTime: pageLoadTime, // You can add custom properties here
      },
    });
    console.log(pageLoadTime)
  }, [location]);

  return (

    <div className="App">
      <StickyHeader />
      <Container className="container" style={{ padding: 0, maxWidth: 1024 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book-your-green-ride" element={<NewBookingForm />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/extra-safety" element={<ExtraSafetyMeasures />} />
          <Route path="/grp-business-users" element={<GrpBusinessUser />} />
          <Route path="/grp-business-user-app" element={<GrpBusinessUserApp />} />
          <Route path="/grp-drivers" element={<GrpDriver />} />
          <Route path="/grp-partner-users" element={<GrpPartnerUser />} />
          <Route path="/grp-partner-user-app" element={<GrpPartnerUserApp />} />
          <Route path="/grp-users" element={<GrpUser />} />
          <Route path="/why-grp" element={<WhyGrp />} />
          <Route path="/why-byd" element={<WhyBYD />} />
          <Route path="/refer-friend" element={<GrpReferFriend />} />
          <Route path="/last-minute-booking" element={<GrpLastMinuteBooking />} />
          <Route path="/book-now" element={<GrpLastMinuteBooking />} />
          <Route path="/pune-t2" element={<GrpLastMinuteBooking />} />
          <Route path="/pune-mumbai" element={<GrpLastMinuteBooking />} />
          <Route path="/t2-pune" element={<GrpLastMinuteBooking />} />
          <Route path="/t1-pune" element={<GrpLastMinuteBooking />} />
          <Route path="/book" element={<GrpLastMinuteBooking />} />        
          <Route path="/grpapps" element={<GrpAppQRCode />} />
          <Route path="/grpuser-qrcode" element={<GrpAppQRCodeUser />} />
          <Route path="/grpdriver-qrcode" element={<GrpAppQRCodeDriver />} />
          <Route path="/grppartner-qrcode" element={<GrpAppQRCodePartner />} />
          <Route path="/grpbusiness-qrcode" element={<GrpAppQRCodeBusiness />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

export default App;
