import { Booking, BookingInput } from './../models/booking';
import { User } from "../models/User";
import { RequestBookingService } from "./RequestBookingService"
import { createBooking } from "./BookingService"
import { AppContext } from './AppContext'



const getBookingDateUTC = (selectedTime: string): string => {
    const now = new Date();

    const timeMapping: { [key: string]: number } = {
        "Immediate": 0,
        "Need in 30 mins": 30,
        "Need in 1 hour": 60,
        "Need in 1.5 hours": 90,
        "Need in 2 hours": 120,
        "Need in 2.5 hours": 150,
        "Need in 3 hours": 180,
        "Need in 3.5 hours": 210,
        "Need in 4 hours": 240
    };

    const minutesToAdd = timeMapping[selectedTime] || 0;
    now.setUTCMinutes(now.getUTCMinutes() + minutesToAdd);

    return now.toISOString();
};

export const createNewBookingService = async (user: User, bookingInput: Partial<BookingInput>): Promise<Booking | null> => {
    let bookingDate;

    const sourceAddress = RequestBookingService.getSourceFormattedAddress()
    const destinationddress = RequestBookingService.getDestinationFormattedAddress()

    bookingDate = AppContext.grpLastMinuteBooking ? getBookingDateUTC(bookingInput.bookingDate || '')
        : (bookingInput.bookingDate || '');

    const booking: BookingInput = {
        userId: user ? user._id : "",
        name: bookingInput.name ? bookingInput.name : '',
        bookingId: RequestBookingService.getNewBookingId(),
        phone: AppContext.phone,
        createdDate: (new Date()).toISOString(),
        bookingDate: bookingDate,
        fromCity: RequestBookingService.getCityFromAddress(sourceAddress),
        toCity: RequestBookingService.getCityFromAddress(destinationddress),
        fromAddress: sourceAddress,
        toAddress: destinationddress,
        totalDistance: RequestBookingService.getRequestBooking().distance,
        returnRide: RequestBookingService.getRequestBooking().booking.returnRide,
        numberOfPassengers: parseInt(RequestBookingService.getRequestBooking().booking.numberOfPassengers === null ? "0" : RequestBookingService.getRequestBooking().booking.numberOfPassengers.toString()),
        numberOfBigBags: parseInt(RequestBookingService.getRequestBooking().booking.numberOfBigBags === null ? "0" : RequestBookingService.getRequestBooking().booking.numberOfBigBags.toString()),
        selectedTolls: RequestBookingService.getSelectedTolls() || ' ',
        carId: bookingInput.carId ? bookingInput.carId : '',
        driverId: bookingInput.driverId ? bookingInput.driverId : '',
        partnerId: AppContext.partnerId,
        corporateId: AppContext.emptyObjectId,
        referenceId: AppContext.emptyObjectId,
        couponCode: 'NA',
        payment: {
            paymentId: "000000",
            paymentType: "NOT_SELECTED",
            AdvancePayment: 0,
            baseFare: RequestBookingService.getRequestBooking().booking.payment.baseFare,
            gstFare: RequestBookingService.getRequestBooking().booking.payment.gstFare,
            toll: RequestBookingService.getRequestBooking().booking.payment.toll,
            parking: RequestBookingService.getRequestBooking().booking.payment.parking,
            totalFare: RequestBookingService.getRequestBooking().booking.payment.totalFare,
            extraKms: RequestBookingService.getRequestBooking().booking.payment.extraKms,
            extraKmCharges: RequestBookingService.getRequestBooking().booking.payment.extraKmCharges,
            waitingTime: 0,
            waitingCharges: 0,
            carrierCharges: 0,
            extraPickorDrop: 0,
            extraPickorDropCharges: 0,
            discount: RequestBookingService.getRequestBooking().booking.payment.discount,
            created: "NOT_CREATED",
            status: "NOT_PAID",
        },
        ride: {
            startTime: "NA",
            endTime: "NA",
            rideOtp: "0000",
            customerRating: "0",
            driverRating: "0",
            commentByCustomer: "NA",
            commentByDriver: "NA"
        },
        notification: {
            confirmationMsg: false,
            cabDetailsMsg: false,
            driverMsg: false
        },
        status: "NEW"
    }

    const createdBooking = await createBooking(booking);

    return createdBooking;


};

