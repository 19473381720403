import { Box, Card, CardContent, Typography } from '@mui/material';

import { mdiCamera  } from '@mdi/js';
import { mdiVideo  } from '@mdi/js';
import { mdiSeatbelt  } from '@mdi/js';
import { mdiCellphoneMarker  } from '@mdi/js';
import Icon from '@mdi/react';

interface CustomCardSafetyProps {
    title: string;
    description: string;
}

const CustomCardSafety = ({ title, description }: CustomCardSafetyProps) => {
    return (
        <Card
            sx={{
                maxWidth: 400,
                margin: 'auto',
                mt: 5,
                background: 'linear-gradient(135deg, rgb(227, 0, 200),rgb(245, 140, 232))',
                color: 'white',
                borderRadius: '16px',
                boxShadow: 3,
                textAlign: 'center',
            }}
        >
            <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h6" align='left'>{description}
                    <br></br><br></br><Icon path={mdiCamera} size={1} /> Next-Gen KENT CamEye
                    <br></br><Icon path={mdiVideo } size={1} />  Provide Video link 
                    <br></br><Icon path={mdiSeatbelt} size={1} /> Safe & Reliable
                    <br></br><Icon path={mdiCellphoneMarker } size={1} /> GPS tracking
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CustomCardSafety;
