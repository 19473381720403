import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';


const CarouselBooking: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box sx={{ width: '100%', margin: '0 auto', padding: '0px 0px 0px 0px' }}>
      <Slider {...settings}>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-20.jpg'}
            alt="Book a Premium Ride"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-1.jpg'}
            alt="Book a Premium Ride"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-10.jpg'}
            alt="Book a Premium Ride"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
      </Slider>
    </Box >
  );
};

export default CarouselBooking;
