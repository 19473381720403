import React, { useState } from 'react';
import { Button, Box, Typography, Divider, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLoadScript } from '@react-google-maps/api';
import { CalendarToday } from '@mui/icons-material';
import SourceDestination from '../components/booking/common/SourceDestination'
import { dateFormat } from '../components/booking/components/common/dateUtils'
import CardImageRight from '../components/booking/common/CardImageRight'
import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';
import EstimatedFare from '../components/booking/common/EstimatedFare'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { emptyFormValues } from '../components/booking/components/common/bookingUtils'
import Alert from '@mui/material/Alert';
import { createOrAddUserDetails } from "../components/services/createOrAddUserDetailsService"
import { Booking, } from '../components/models/booking'
import { AppContext } from '../components/services/AppContext'
import BookingDetails from '../components/booking/common/BookingDetails'
import { createOrGetUser } from "../components/services/createOrGetUserService"
import { createNewBookingService } from "../components/services/createNewBookingService"
import ProgressMessage from "../components/booking/common/ProgressMessage"
import '../styles/commonStyle.css';
import { RequestBookingService } from "../components/services/RequestBookingService"
import { UpdateTotalRides } from "../components/services/userService"

interface Form3Props {
  formValues: any;
  handleBack: () => void;
}

const NewBookingForm3: React.FC<Form3Props> = ({ formValues, handleBack, }) => {

  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [createdBooking, setCreatedBooking] = useState<Booking>()
  const bookingDateTime = `${formValues.bookingDate}T${formValues.bookingTime?.trimLeft()}`;

  const convertDateTimetoUTC = (dateTime: string) => {
    const localDate = new Date(dateTime);
    const userUtcOffset = localDate.getTimezoneOffset();

    localDate.setMinutes(localDate.getMinutes() - userUtcOffset - 330);

    return localDate.toISOString();
  };

  const handleSubmit = async () => {
    // console.log("Submit button clicked!");
    AppContext.grpLastMinuteBooking = false

    setLoading(true);
    setProgressMessage("Please Wait....");
    const user = await createOrGetUser(formValues.name, AppContext.phone)
    if (user)
      await createOrAddUserDetails(user)

    const bookingData = {
      name: formValues.name,
      bookingDate: convertDateTimetoUTC(bookingDateTime),
      carId: AppContext.emptyObjectId,
      driverId: AppContext.emptyObjectId
    };

    let data: Booking | null = null;

    setTimeout(async () => {
      if (user)
        data = await createNewBookingService(user, bookingData);
      // console.log("data  " + JSON.stringify(data))
      if (data) {
        setMessage("Your booking request has been accepted. We will check the availability and send a confirmation to your WhatsApp number shortly.");
        setCreatedBooking(data)
        setIsSuccess(true);
        UpdateTotalRides(user ? user?._id : '')
        formValues = emptyFormValues()
        RequestBookingService.setToInitialState()
        AppContext.travelDate = ""
        AppContext.travelTime = ""
        setLoading(false)
      } else {
        setMessage("Booking could not be created. Please try again later.");
        formValues = emptyFormValues()
        setIsSuccess(false);
        setLoading(false)
      }

    }, 1000);
  };

  return (
    <div>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        {loading && (<ProgressMessage progressMessage={progressMessage} />

        )}
        {message ? (
          <Grid size={{ xs: 12, sm: 12, md: 12 }}>
            <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
              Booking Confirmation
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Alert severity={isSuccess ? "success" : "error"}
              icon={isSuccess ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: isSuccess ? '#e8f5e9' : '#f8d7da',
                color: isSuccess ? '#388e3c' : '#721c24',
                borderRadius: 2,
                fontSize: 18,
                width: "90%",
                marginTop: 3
              }}>
              {message}
            </Alert>
            <BookingDetails booking={createdBooking} />
          </Grid>
        ) : (
            <>
              <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
                  Your Booking Request - Review
              </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <Box display="flex" alignItems="center">
                  <CalendarToday sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
                  <Typography sx={{ mr: 1, mb: 2 }}>Booking: {dateFormat(bookingDateTime)}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <PeopleIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
                  <Typography sx={{ mr: 1, mb: 2 }}>Number of Passengers: {formValues.numberOfPassengers}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LuggageIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
                  <Typography sx={{ mr: 1, mb: 2 }}>Number of Big Bags: {formValues.numberOfBigBags}</Typography>
                </Box>
                <Divider sx={{ mb: 1 }} />
              </Grid>


              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <SourceDestination source={formValues.source} destination={formValues.destination} />
                <Divider sx={{ mt: 1 }} />
              </Grid>

              <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                <CardImageRight header={'Luxury SUV+'} text1={'Electric BYD E6'} text2={'Extra Leg Room'} text3={'Extra Luggage Space'} imageSource={require('../images/BYD1.jpg')} ></CardImageRight>
              </Grid>

              <EstimatedFare />

              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Button variant="contained" color="primary" onClick={handleBack}
                  className='back-button'
                >Back</Button>
              </Grid>

              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Button variant="contained" color="primary"
                  onClick={handleSubmit}
                  className='primary-button'
                >
                  Submit
              </Button>
              </Grid>
            </>)}
      </Grid>

      <br></br>
    </div>
  );
};

export default NewBookingForm3;