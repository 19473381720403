import React from "react";
import { CircularProgress, Typography, Box, Grid } from "@mui/material";

interface ProgressMessageProps {
  progressMessage: string;
}

const ProgressMessage: React.FC<ProgressMessageProps> = ({ progressMessage, }) => {

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" // Full screen height
      width="100%"
      position="fixed"
      top={0}
      left={0}
      bgcolor="rgba(255, 255, 255, 0.8)" // Light overlay effect
      zIndex={9999} // Ensures it's above other elements
    >
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <CircularProgress />
        <Typography variant="body2" sx={{ mt: 2 }}>{progressMessage}</Typography>
      </Grid>
    </Box>
  );
};

export default ProgressMessage;
