import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import GrpIconButton from './GrpIconButton';
import WhatsAppButtonShort from './WhatsAppButtonShort';
import FloatingPhoneButton from './FloatingPhoneButton';


const StickyHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FloatingPhoneButton></FloatingPhoneButton>
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 1000,
          backgroundColor: 'white', //#3f51b5
          color: 'green',
          paddingLeft: '1px',
          paddingRight: '1px',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'left' }} component={Link} to="/">
            <img src={logo} alt="Logo" style={{ marginRight: '4px', height: '44px' }} />
          </Box>
          <Box >
            <Box >
              <GrpIconButton name={'Book Ride'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
              &nbsp;&nbsp;
              {/* <WhatsAppButtonShort></WhatsAppButtonShort>
            &nbsp;&nbsp; */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                style={{ marginRight: '1px', height: '44px' }}
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>

              {/* Dropdown Menu */}
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose} component={Link} to="/book-your-green-ride">Book Your Green Ride</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/why-grp">Why Green Rides Pune?</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/extra-safety">Extra Safety Measures</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/grp-users">GRP User</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/grp-drivers">GRP Drivers</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/grp-partner-users">GRP Partners</MenuItem>
                {/* <MenuItem onClick={handleMenuClose} component={Link} to="/grp-partner-user-app">GRP Partner App</MenuItem> */}
                <MenuItem onClick={handleMenuClose} component={Link} to="/grp-business-users">GRP Business/Corporate Users</MenuItem>
                {/* <MenuItem onClick={handleMenuClose} component={Link} to="/grp-business-user-app">GRP Business App</MenuItem> */}
                <MenuItem onClick={handleMenuClose} component={Link} to="/contactus">Contact Us</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/termsandconditions">Terms & Conditions</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/privacypolicy">Privacy Policy</MenuItem>

              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Simulate scrollable content
      <Box sx={{ height: '2000px', padding: '20px', backgroundColor: '#f0f0f0' }}>
        <Typography variant="body1">
          Scroll down to see the sticky header in action. This is an example of scrollable content.
          The header will remain fixed at the top while the rest of the content scrolls.
        </Typography>
      </Box> */}
    </>
  );
};

export default StickyHeader;
