import { User, UserInput } from "../models/User"
import { AuthDetails } from "../models/AuthDetails";
import client from './apolloClient';
import { VALIDATE_USER, SEND_OTP, GET_USER_BY_PHONE, } from './queries';
import { CREATE_NEW_USER, UPDATE_TOTAL_RIDES } from './mutation';
import { OtpDetails } from '../models/OtpDetails';
import { ApolloError } from '@apollo/client';

export const validateUser = async (phone: string, mpin: string): Promise<AuthDetails> => {
    //  console.log("validate user called in service")
    //  console.log( phone , mpin)
    try {
        const { data } = await client.query({
            query: VALIDATE_USER,
            variables: { phone, mpin },
        });

        // console.log('Response data in userservice:', data);
        return data?.validateUser;
    } catch (error) {
         console.log('Error fetching validate user:', error);
        return { userId: "", authToken: "", created: "", message: "Error : API call issues!", phone: phone };
    }
}

export const sendOtp = async (phone: string): Promise<OtpDetails> => {
    // console.log(`sendOtp ${phone}`);    
    try {
        const { data } = await client.query({
            query: SEND_OTP,
            variables: { phone },
        });
        return data?.sendOtp;
    } catch (error) {
        console.log('Error fetching sendotp:', error);
        return { message: "OTP is not valid!", status: "false" };
    }
}

export const getUserByPhone = async (phone: string, ): Promise<User> => {
    try {
        const { data } = await client.query({
            query: GET_USER_BY_PHONE,
            variables: { phone },
        });
        const userData = data?.getUserByPhone;
        if (null) {
            return { _id: "", name: '', phone: '', email: '', mpin: '', status: '', userBrand: '', userRating: 0, totalRides: 0 };
        }
        return userData;
    } catch (error) {
        console.log('Error fetching user by phone in service:', error);
        // throw new Error(`User with phone ${phone} not found`);
        return { _id: "", name: '', phone: '', email: '', mpin: '', status: '', userBrand: '', userRating: 0, totalRides: 0 };
    }
}

export const createNewUser = async (newUser: UserInput): Promise<User> => {
    //  console.log("new User = "+JSON.stringify(newUser))
    try {
        const { data } = await client.mutate({
            mutation: CREATE_NEW_USER,
            variables: { newUser },

        });
        return data?.createUser;
    } catch (error) {
        console.log('Error fetching create new user:', error);
        return { _id: "", name: "", phone: "", email: "", mpin: "", status: "", userBrand: '', userRating: 0, totalRides: 0 };;
    }
}

export const UpdateTotalRides = async (Id: string): Promise<User | null> => {

    try {
        const { data } = await client.mutate({
            mutation: UPDATE_TOTAL_RIDES,
            variables: { Id },

        });
        return data?.updateUserTotalRides;
    } catch (error) {
        console.log('Error fetching UpdateTotalRids: ', error);
        return null;
    }
}

