import React from 'react';
import { Button, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/LocalTaxi';

interface GrpIconButtonProps {
    name: string;
    redirectUrl: string;
}

const GrpIconButton : React.FC<GrpIconButtonProps> = ({ name, redirectUrl }) => {

    return (
        <>
            <Button variant="contained"
                color="primary"
                href={redirectUrl}
                startIcon={<WhatsAppIcon />}
                sx={{
                    color: 'white',              // Text color
                    background: 'linear-gradient(45deg,rgb(5, 211, 15) 30%,rgb(85, 184, 79) 90%)',
                    '&:hover': {
                        backgroundColor: '#1ebe57', // Darker green on hover
                    },
                    textTransform: "none",
                    padding: "7px 14px",
                    fontSize: "16px",
                    WebkitTextFillColor: 'white',
                }}
            >{name}</Button>
        </>
    );
};

export default GrpIconButton ;





