// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselByd from '../components/CarouselByd';
import GrpIconButton from '../components/GrpIconButton';


const WhyBYD: React.FC = () => {

  return (
    <div>
      <CarouselByd></CarouselByd>
      <br></br>
      <Typography textAlign="left" variant="h4">BYD E6 Electric Elegance</Typography>
      <br/>
      <Typography textAlign="left" variant="h5">
        Long Battery Life 🔋
      </Typography>
      <Typography textAlign="left" variant="body1">
        Equipped with a 71.7 kWh Blade Battery, offering a range of up to 520 km (WLTP cycle) on a single charge.
        <br></br>The battery is cobalt-free and known for its durability, safety, and long lifespan.
      </Typography>
      <br />
      <Typography textAlign="left" variant="h5">
        Spacious Design 🚗
      </Typography>
      <Typography textAlign="left" variant="body1">
        The BYD E6 is a 5-seater MPV (Multi-Purpose Vehicle) with a roomy interior.
        <br></br>It offers 580 liters of boot space, making it great for both passengers and luggage.
      </Typography>
      <br />
      <Typography textAlign="left" variant="h5">
        Low Maintenance Costs 💰
      </Typography>
      <Typography textAlign="left" variant="body1">
        Electric vehicles generally have fewer moving parts, which reduces maintenance expenses.
        <br></br>The BYD E6 is designed for commercial use, ensuring reliability and lower running costs.
      </Typography>
      <br />
      <Typography textAlign="left" variant="h5">
        Safety Features 🛡️
      </Typography>
      <Typography textAlign="left" variant="body1">
        Equipped with ABS, EBD, and airbags.
        <br></br>BYD’s Blade Battery technology is highly resistant to fire and puncture, making it one of the safest EV batteries.
      </Typography>
      <br />
      <Typography textAlign="left" variant="h5">
        Eco-Friendly Option 🌱
      </Typography>
      <Typography textAlign="left" variant="body1">
        Zero tailpipe emissions, making it a great choice for environmentally conscious businesses and cities.
      </Typography>
      <br />
      <Typography textAlign="left" variant="h5">
        Comfort & Technology 🎯
      </Typography>
      <Typography textAlign="left" variant="body1">
        Features like automatic climate control, touchscreen infotainment system, and comfortable seating.
      </Typography>
      <br />
      <GrpIconButton name={'BOOK RIDE NOW'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
      <br /><br />
    </div>
  );
};

export default WhyBYD;
