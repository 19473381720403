import { gql } from '@apollo/client';


export const VALIDATE_USER = gql`query ValidateUser($phone: String!, $mpin: String!) {
  validateUser(phone: $phone, mpin: $mpin)  {
    userId
    phone
    authToken
    created
    message
  }
}
`;
export const SEND_OTP = gql`
query SendOtp($phone: String!) {
  sendOtp(phone: $phone) {
    message
    status
  }
}
`
export const GET_CARS = gql`query GetCars {
  getCars {
    _id
    carModel
    carNumber
    carType
    status
  }
}
`;

export const GET_DRIVERS = gql`query GetDrivers {
getDrivers {
  _id
  userId
  name
  phone
  status
}
}
`;

export const GET_USER_BY_USERID = gql`
query GetUserByUserId($_id: ID!) {
  getUserByUserId(_id: $_id) {
    _id
    name
    phone
    email
    status
    mpin
    totalRides
    userBrand
    userRating
  }
}
`

export const GET_USER_BY_PHONE = gql`
query GetUserByPhone($phone: String!) {
  getUserByPhone(phone: $phone) {
    _id
    name
    phone
    email
    status
    mpin
    totalRides
    userBrand
    userRating
  }
}
`
export const GET_USERDETAILS_BY_USERID = gql`
query GetUserDetailsByUserId($userId: ID!) {
  getUserDetailsByUserId(userId: $userId) {
    _id
    userId
    gstNumber
    billingAddress
    savedAddresses {
      _id
      placeId
      description
      latitude
      longitude
    }
  }
}
`

export const GET_SAVED_ADDRESS_BY_DESCRIPTION = gql`
query GetSaveAddressByDescription($userId: String!, $description: String!) {
  getSaveAddressByDescription(userId: $userId, description: $description) {
    _id
    userId
    gstNumber
    billingAddress
    savedAddresses {
      _id
      placeId
      description
      latitude
      longitude
    }
  }
}
`
export const GET_YESTERDAY_ONWARD_BOOKINGS = gql`
query GetYesterdayOnwardsBookings {
  getYesterdayOnwardsBookings {
    _id
    userId
    name
    passengerName
    bookingId
    phone
    passengerPhone
    createdDate
    bookingDate
    fromCity
    toCity
    fromAddress
    toAddress
    selectedTolls
    totalDistance
    returnRide
    numberOfPassengers
    numberOfBigBags
    carId
    driverId
    referenceId
    corporateId
    couponCode
    ride {
      startTime
      endTime
      rideOtp
      customerRating
      driverRating
      commentByCustomer
      commentByDriver
    }
    payment {
      paymentId
      paymentType
      AdvancePayment
      baseFare
      gstFare
      toll
      parking
      waitingCharges
      extraKmCharges
      totalFare
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
      created
      status
    }
    partnerId
    notification {
      confirmationMsg
      cabDetailsMsg
      driverMsg
    }
    status
  }
}
`;


export const GET_BOOKINGS_BY_USERID = gql`
query GetBookingsByUserId($userId: ID!) {
  getBookingsByUserId(userId: $userId) {
     _id
    userId
    name
    bookingId
    phone
    createdDate
    bookingDate
    fromCity
    toCity
    fromAddress
    toAddress
    returnRide
    totalDistance
    carId
    driverId
    ride {
      startTime
      endTime
      rideOtp
      customerRating
      driverRating
      commentByCustomer
      commentByDriver
    }
     payment {
      paymentId
      paymentType
      baseFare
      gstFare
      toll
      parking
      waitingCharges
      extraKmCharges
      totalFare
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
      created
      status
    }
    partnerId
    notification {
      confirmationMsg
      cabDetailsMsg
      driverMsg
    }
    status
   
  }

}`

export const GET_INVOICE_BY_BOOKINGID = gql`
query GetInvoiceByBookingId($bookingId: String!) {
  getInvoiceByBookingId(bookingId: $bookingId) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
    payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`
export const GET_STATIC_DATA = gql`
query GetStaticData {
  getStaticData {
    TermsAndCondition
    RideInstructions
    ImageUrls {
      uri
      title
      content
    }
    BydUrls {
      uri
      title
      content
    }
  }
}
`
export const CALCULATE_FARE = gql`
query CalculateFare($booking: FareCalculationInput) {
  calculateFare(booking: $booking) {
    paymentId
    paymentType
    AdvancePayment
    baseFare
    gstFare
    toll
    parking
    waitingCharges
    extraKmCharges
    totalFare
    extraKms
    waitingTime
    carrierCharges
    extraPickorDrop
    extraPickorDropCharges
    discount
    created
    status
  }
}

`

export const GET_LAST_MINUTE_CARS = gql`
query GetLastMinuteCars {
  getLastMinuteCars {
    _id
    carId
    driverId
    carType
    carRoute
    carLocation
    carTo
    fare
    availableIn
    availabilitySetTime
  }
}
  `

export const SEND_BOOKINGMSG_USER = gql`
query Query($booking: BookingInput) {
  sendBookingMsgToUser(booking: $booking)
}
  `

export const SEND_BOOKINGMSG_DRIVER = gql`
query Query($booking: BookingInput, $driverId: String) {
  sendBookingMsgToDriver(booking: $booking, driverId: $driverId)
}
  `