// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
    background-color: grey !important;
    color: white !important;
    width: 90%;
  }
  
  .back-button:hover {
    background-color: grey !important;
  }
  
  .primary-button {
    background-color:'primary.main', !important;
    color: white !important;
    width: 90%;
  }
  
  .primary-button:hover {
    background-color: 'primary.dark' !important;
  }


  
  
  
  `, "",{"version":3,"sources":["webpack://./src/styles/commonStyle.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,UAAU;EACZ;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,2CAA2C;IAC3C,uBAAuB;IACvB,UAAU;EACZ;;EAEA;IACE,2CAA2C;EAC7C","sourcesContent":[".back-button {\r\n    background-color: grey !important;\r\n    color: white !important;\r\n    width: 90%;\r\n  }\r\n  \r\n  .back-button:hover {\r\n    background-color: grey !important;\r\n  }\r\n  \r\n  .primary-button {\r\n    background-color:'primary.main', !important;\r\n    color: white !important;\r\n    width: 90%;\r\n  }\r\n  \r\n  .primary-button:hover {\r\n    background-color: 'primary.dark' !important;\r\n  }\r\n\r\n\r\n  \r\n  \r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
