import { UserDetails } from "../models/UserDetails";
import { User } from "../models/User";
import { RequestBookingService } from "./RequestBookingService"
import { getSavedAddressbyDescription, addToSaveAddress, createNewUserDetails, getUserDetailsByUserid } from "./userDetailsService"


export const createOrAddUserDetails = async (user: User): Promise<UserDetails | null> => {
    const source = RequestBookingService.getRequestBooking().source;
    const destination = RequestBookingService.getRequestBooking().destination;

    const isAirportAddress = source?.name?.startsWith("Terminal 1") || source?.formatted_address?.includes("T1, Navpada,") || source?.name?.startsWith("Terminal 2") || source?.formatted_address?.includes("Terminal 2");

    const addressToSave = isAirportAddress ? destination : source;

    try {
        let userDetails = await getUserDetailsByUserid(user._id);

        if (!userDetails) {
            userDetails = await createNewDetails(user, addressToSave);
        } else {
            await saveAddressIfNotExists(user._id, addressToSave);
        }

        return userDetails;
    } catch (error) {
        console.log("Error fetching or creating user details:", error);
        return null;
    }
};

const saveAddressIfNotExists = async (userId: string, place: google.maps.places.PlaceResult | null) => {
    if (!place) return;

    const existingSavedAddress = await getSavedAddressbyDescription(userId, place.formatted_address);
    const addressToSave = getLocationData(place)

    if (!existingSavedAddress) {
        await addToSaveAddress(userId, addressToSave);
    }
};

const createNewDetails = async (newUser: User, address: google.maps.places.PlaceResult | null): Promise<UserDetails> => {

    const addressToSave = getLocationData(address)

    return await createNewUserDetails({
        userId: newUser._id,
        gstNumber: "NA",
        billingAddress: "NA",
        savedAddresses: [addressToSave]
    });
};

const getLocationData = (place: google.maps.places.PlaceResult | null) => {

    const lat = place?.geometry?.location?.lat ? place.geometry.location.lat() : 0;
    const lng = place?.geometry?.location?.lng ? place.geometry.location.lng() : 0;

    return {
        placeId: place?.place_id || "",
        description: place?.formatted_address || "",
        latitude: lat,
        longitude: lng
    };
};