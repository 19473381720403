import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppButton from '../components/WhatsAppButton';


// Replace with your desired location coordinates


const ContactUs: React.FC = () => {

  const webUrl = process.env.REACT_APP_URL;
  const backendurl = process.env.REACT_APP_BACKEND_URL;

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: 18.5743166,
    lng: 73.7747751
  };

  return (
    <div>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <video width="100%" autoPlay loop muted>
        <source src={`${webUrl}images/ec1.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <br></br>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <Typography textAlign="left" variant="body1">
            GREEN RIDES PUNE, <br />
            201-Metropolis,<br />
            Balewadi High Street, <br />
            Balewadi, Pune - 411045, INDIA. <br />
            Number: +91-8600-57-2727<br />
            Email: contact@greenrides.in<br />
          </Typography>
          <WhatsAppButton></WhatsAppButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <a href="https://www.google.com/maps/place/Green+Rides+Pune/@18.5752004,73.7726852,15z/data=!4m6!3m5!1s0x3bc2b9b76defa29b:0x3763a144d474613a!8m2!3d18.574423!4d73.7748723!16s%2Fg%2F11stvh9b3j?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">
            <img
              src={`${webUrl}/images/Contact_us.jpg`}
              alt="Green Rides Pune"
              style={{ width: '100%', height: 'auto', border: '1px solid grey' }}
            />
          </a>

        </Grid>
      </Grid>
      <br /><br />
      {`URL : ${backendurl}`}
    </div>
  );
};

export default ContactUs;

