import React from "react";

interface ImageWithTextProps {
  imageUrl: string;
  text1: string;
  text2: string;
}

const ImageWithText: React.FC<ImageWithTextProps> = ({ imageUrl, text1, text2 }) => {
  return (
    <div className="image-container">
      <img src={imageUrl} alt="Example" />
      <p className="overlay-text">{text1}</p>

      <p className="overlay-text1">{text2}</p>
    </div>
  );
};

export default ImageWithText;