import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import ClickImage from './ClickImage';

const CarouselHome: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box sx={{ width: '100%', margin: '0 auto', padding: '0px 0px 0px 0px' }}>
      <Slider {...settings}>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-1.jpg'}
            alt="Green Rides Pune - Welcome"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-2.jpg'}
            alt="We love our customers"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-3.jpg'}
            alt="Green Rides Pune - Welcome"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-4.jpg'}
            alt="We love our customers"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-5.jpg'}
            alt="Green Rides Pune - Welcome"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-6.jpg'}
            alt="We love our customers"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-7.jpg'}
            alt="Green Rides Pune - Welcome"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={'/images/GrpImages/GrpImages-8.jpg'}
            alt="We love our customers"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            loading='lazy'
          />
        </div>
      </Slider>
    </Box>
  );
};

export default CarouselHome;
