import React, { useState } from 'react';
import { Button, Box, Typography, TextField, CircularProgress, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLoadScript } from '@react-google-maps/api';

import { useNavigate } from 'react-router-dom';
import CountryCodePicker from '../components/booking/common/CountryCodePicker'
import { sendOtp } from '../components/services/userService'
import { validateUser } from '../components/services/userService';
import { AuthDetails } from '../components/models/AuthDetails';
import { AppContext } from '../components/services/AppContext'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createBooking } from "../components/services/BookingService"
import { BookingInput } from '../components/models/booking'
import { RequestBookingService } from "../components/services/RequestBookingService"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { emptyFormValues } from '../components/booking/components/common/bookingUtils'
import '../styles/CommonCSS.css';

const libraries: ("places")[] = ["places"];

interface Form4Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // handleNext: () => void;
  handleBack: () => void;
}


const NewBookingForm4: React.FC<Form4Props> = ({ formValues, handleInputChange, handleBack }) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Use environment variable for API key
    libraries: libraries
  });

  const navigate = useNavigate();

  const theme = useTheme();

  const [showAdditionalField, setShowAdditionalField] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [reviewBtnDisable, setReviewBtnDisable] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [otpRequestCount, setOtpRequestCount] = useState(0);
  const [otpCooldown, setOtpCooldown] = useState(false);
  const [cooldownEndTime, setCooldownEndTime] = useState<number | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [progressMessage, setProgressMessage] = useState<string | null>(null);



  // const userId = AppContext.loggedUserId;
  // const userId = "666da1d20a9bfb1b1f6924d5"
  const bookingDateTime = `${formValues.bookingDate}T${formValues.bookingTime?.trimLeft()}`;

  const convertISTtoUTC = (istDateTime: string) => {
    const istDateObject = new Date(istDateTime);
    return istDateObject.toISOString();
  };

  const handleVerifyClick = async () => {
    // const result = await sendOtp(AppContext.phone);
    // if (result.status === "false") { return; }
    // setShowAdditionalField(true); 


    if (AppContext && AppContext.phone.length < 8) {
      setSnackbarMessage(`Please enter the phone number`);
      setSnackbarOpen(true);
      return
    }


    const currentTime = Date.now();
    const storedOtpCount = parseInt(localStorage.getItem('otpRequestCount') || '0', 10);
    const storedCooldown = localStorage.getItem('otpCooldown') === 'true';
    const storedCooldownEndTime = parseInt(localStorage.getItem('cooldownEndTime') || '0', 10);

    // Load stored state
    setOtpRequestCount(storedOtpCount);
    setOtpCooldown(storedCooldown);
    setCooldownEndTime(storedCooldownEndTime);

    if (storedCooldown) {
      const timeLeft = Math.max(0, storedCooldownEndTime - currentTime);
      const minutesLeft = Math.ceil(timeLeft / 60000);
      setSnackbarMessage(`Please wait ${minutesLeft} minute(s) before requesting another OTP.`);
      setSnackbarOpen(true);
      return;
    }

    if (storedOtpCount > 3) {
      setOtpCooldown(true);
      const newCooldownEndTime = Date.now() + 10 * 60000;
      setCooldownEndTime(newCooldownEndTime);
      localStorage.setItem('cooldownEndTime', newCooldownEndTime.toString());
      localStorage.setItem('otpCooldown', 'true');
      setSnackbarMessage("You have reached the maximum OTP requests. Please try again in 10 minutes.");
      setSnackbarOpen(true);
      return;
    }

    const result = await sendOtp(AppContext.phone);
    console.log("result = " + JSON.stringify(result))
    if (result.status === "false") { return; }

    const newOtpCount = storedOtpCount + 1;
    setOtpRequestCount(newOtpCount);
    localStorage.setItem('otpRequestCount', newOtpCount.toString());
    setShowAdditionalField(true);
  };

  // Reset count when cooldown is over
  React.useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (otpCooldown) {
      timer = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime >= cooldownEndTime!) {
          setOtpCooldown(false);
          setOtpRequestCount(0);
          localStorage.setItem('otpRequestCount', '0');
          localStorage.setItem('otpCooldown', 'false');
          clearInterval(timer!);
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [otpCooldown, cooldownEndTime]);


  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);

  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  const handleOtpVerifyClick = async () => {
    if (!otp || otp.length < 6) {
      //  alert("OTP verification failed!");
      setSnackbarMessage("Please enter proper Otp!");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setProgressMessage("Verifying your Otp...")
    setTimeout(async () => {
      try {
        const authDetails: AuthDetails = await validateUser(AppContext.phone, otp);
        if (authDetails.userId !== '') {
          AppContext.loggedUserId = authDetails.userId;
          setPhoneVerified(true);
          setShowAdditionalField(false);

        } else {
          // alert("OTP verification failed. Please try again!");
          setSnackbarMessage("OTP verification failed. Please try again!!");
          setOtp('')
          setSnackbarOpen(true);
        }
      } catch (err) {
        console.error(err);
        alert("An error occurred during OTP verification. Please try again.");
      } finally {
        setLoading(false);
        setShowAdditionalField(false); // Optionally hide the OTP input after verification
      }
    }, 2000); // Set the delay here (2000ms = 2 seconds)
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };



  const handleSubmit = async () => {
    if (!name) {
      setSnackbarMessage("Please make sure to fill in all required fields before proceeding.5");
      setSnackbarOpen(true);
      return
    }
    console.log(' submit called.....');
    setLoading(true);
    setProgressMessage("Please Wait....");
    console.log('Form Submitted:', formValues);

    const newBooking: BookingInput = {
      userId: AppContext.loggedUserId,
      name: name,
      bookingId: RequestBookingService.getNewBookingId(),
      phone: AppContext.phone,
      createdDate: (new Date()).toISOString(),
      bookingDate: convertISTtoUTC(bookingDateTime),
      fromCity: RequestBookingService.getCityFromAddress(formValues.source),
      toCity: RequestBookingService.getCityFromAddress(formValues.destination),
      fromAddress: formValues.source,
      toAddress: formValues.destination,
      returnRide: formValues.returnRide,
      totalDistance: RequestBookingService.getRequestBooking().distance,
      selectedTolls: RequestBookingService.getSelectedTolls() || " ",
      numberOfPassengers: parseInt(formValues.numberOfPassengers),
      numberOfBigBags: parseInt(formValues.numberOfBigBags),
      carId: "000000000000000000000000",
      driverId: "000000000000000000000000",
      partnerId: "666c6b2381bce16768a7b634",
      referenceId: '000000000000000000000000',
      couponCode: '0000000000',
      payment: {
        paymentId: "000000",
        paymentType: "NOT_SELECTED",
        AdvancePayment: 0,
        baseFare: RequestBookingService.getRequestBooking().booking.payment.baseFare,
        gstFare: RequestBookingService.getRequestBooking().booking.payment.gstFare,
        toll: RequestBookingService.getRequestBooking().booking.payment.toll,
        parking: RequestBookingService.getRequestBooking().booking.payment.parking,
        totalFare: RequestBookingService.getRequestBooking().booking.payment.totalFare,
        extraKms: RequestBookingService.getRequestBooking().booking.payment.extraKms,
        extraKmCharges: RequestBookingService.getRequestBooking().booking.payment.extraKmCharges,
        waitingTime: RequestBookingService.getRequestBooking().booking.payment.waitingTime,
        waitingCharges: RequestBookingService.getRequestBooking().booking.payment.waitingCharges,
        carrierCharges: RequestBookingService.getRequestBooking().booking.payment.carrierCharges,
        extraPickorDrop: RequestBookingService.getRequestBooking().booking.payment.extraPickorDrop,
        extraPickorDropCharges: RequestBookingService.getRequestBooking().booking.payment.extraPickorDropCharges,
        discount: 0,
        created: "NOT_CREATED",
        status: "NOT_PAID",
      },
      ride: {
        startTime: "NA",
        endTime: "NA",
        rideOtp: "0000",
        customerRating: "0",
        driverRating: "0",
        commentByCustomer: "NA",
        commentByDriver: "NA"
      },
      notification: {
        confirmationMsg: false,
        cabDetailsMsg: false,
        driverMsg: false
      },
      status: "NEW"
    }
    console.log("newBooking Input= " + JSON.stringify(newBooking))
    setTimeout(async () => {
      const data = await createBooking(newBooking);
      console.log("newBooking data = " + JSON.stringify(data))
      if (data) {
        setMessage("Your booking request is accepted. We will confirm your booking request and send confirmation on your WhatsApp in some time.");
        setIsSuccess(true);
        formValues = emptyFormValues()
        localStorage.setItem('otpRequestCount', '0');
        setLoading(false)
      } else {
        setMessage("Booking could not be created. Please try again.");
        formValues = emptyFormValues()
        localStorage.setItem('otpRequestCount', '0');
        setIsSuccess(false);
        setLoading(false)
      }

    }, 2000);
  };




  if (!isLoaded) return <div>Loading...</div>;
  // / bgcolor: '#f9f9f9'/
  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {loading && (
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <CircularProgress />
            <Typography variant="body2" sx={{ mt: 2 }}>{progressMessage}</Typography>
          </Grid>
        )}
        {message ? (
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Alert severity={isSuccess ? "success" : "error"}
              icon={isSuccess ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: isSuccess ? '#e8f5e9' : '#f8d7da',
                color: isSuccess ? '#388e3c' : '#721c24',
                borderRadius: 2,
                fontSize: 12,
                width: "100%",
              }}>
              {message}
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid size={{ xs: 12, sm: 12, md: 12 }}>
              <TextField
                label="Enter Your Name"
                fullWidth
                name="name"
                value={name}
                onChange={handleNameChange}
                required
                variant="outlined"
                sx={{ bgcolor: 'white', borderRadius: 1, mt: 3, mb: 0 }}
              />
            </Grid>
            <CountryCodePicker phoneText={formValues.phone} phoneVerified={phoneVerified} />

            {phoneVerified && <Typography sx={{ color: 'green', borderRadius: 1, mt: 2, mb: 2 }}> Your WhatsApp number is Verified </Typography>}
            {
              showAdditionalField && (
                <>
                  <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                    {<Typography sx={{ color: 'green', borderRadius: 1, mt: 2, mb: 2, textAlign: "left" }}>We have sent the OTP to your WhatsApp number. </Typography>}
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                    <TextField
                      label="Enter the Otp"
                      variant="outlined"
                      fullWidth
                      value={otp}
                      sx={{ bgcolor: 'white', borderRadius: 1 }}
                      onChange={handleOtpChange}
                      inputProps={{ maxLength: 6 }}
                    />
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Button variant="contained" color="primary" onClick={handleOtpVerifyClick}
                      sx={{
                        bgcolor: 'primary.main',
                        '&:hover': {
                          bgcolor: 'primary.dark'
                        },
                        color: 'white',
                        width: '90%'
                      }}
                      className="customButton"
                    >Verify OTP</Button>

                  </Grid>
                </>
              )
            }
            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                  '& .MuiSnackbarContent-root': {
                    maxWidth: '400px',
                    width: '100%',
                  },
                  position: 'absolute',
                  bottom: '60px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: '90%',
                    width: '90%',
                  },
                }}

                aria-live="assertive"
              >
                <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
              </Snackbar>
            </Grid>

            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Button variant="contained" color="primary" onClick={handleBack}
                sx={{
                  color: 'white', width: '90%'
                }}
                className="customButton"
              >Back
              </Button>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              {
                showAdditionalField === false && phoneVerified === false && (
                  <Button variant="contained" color="primary" onClick={handleVerifyClick}
                    sx={{
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.dark'
                      },
                      color: 'white',
                      width: '90%'
                    }}
                    className="customButton"
                  >Verify Phone</Button>
                )
              }
              {
                phoneVerified && (
                  <Button variant="contained" color="primary" onClick={handleSubmit}
                    sx={{
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.dark'
                      },
                      color: 'white',
                      width: '90%'
                    }}
                    className="customButton"
                  >Request Booking
                  </Button>
                )
              }
            </Grid>
          </>
        )}
      </Grid>
      <br></br>
    </div >
  );
};

export default NewBookingForm4;
