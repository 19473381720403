import React, { memo } from 'react';
import  Grid  from '@mui/material/Grid2';
import './../CSSStyle/CardImageRight.css';

interface Props {
    header: string;
    text1: string;
    text2: string;
    text3: string;
    imageSource: string;
}

const CardImageRight: React.FC<Props> = ({ header, text1, text2, text3, imageSource }) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid  size={12}>
                <div className="card-container">
                    <div className="text-container">
                        <h2 className="header">{header}</h2>
                        <p className="text">{text1}</p>
                        <p className="text">{text2}</p>
                        <p className="text">{text3}</p>
                    </div>

                    <div className="image-container">
                        <img src={imageSource} alt={header} className="image" loading="lazy" />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default memo(CardImageRight);
