import { calculatePayment } from './BookingService';
import { RequestBooking } from './../models/RequestBooking';


export class RequestBookingService {
    static requestBooking: RequestBooking;

    static getRequestBooking(): RequestBooking {
        if (!this.requestBooking) {
            this.set();
        }
        return this.requestBooking;

    }

    static setRequestBooking(request: RequestBooking) {
        this.requestBooking = request

    }

    static setSource(source: string) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.source = source;
    }

    static setDestination(destination: string) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.destination = destination;
    }

    // static getSource(): string  {
    //     if (this.requestBooking)
    //         return this.requestBooking.source;
    //     return this.requestBooking.source
    // }

    // static getDestination(): string {
    //     if (this.requestBooking)
    //         return this.requestBooking.destination

    //     return this.requestBooking.destination
    // }
    static setBookingDate(bookingDate: string) {
        this.requestBooking.bookingDate = bookingDate;
    }

    static setbookingTime(bookingTime: string) {
        this.requestBooking.bookingTime = bookingTime;
    }


    // static getBookingDate(): string | null {
    //     if (this.requestBooking)
    //         return this.requestBooking.bookingDate;

    //     return null
    // }

    // static getbookingTime(): string | null {
    //     if (this.requestBooking)
    //         return this.requestBooking.bookingTime;
    //     return null
    // }

    static setDistance(distance: number) {
        if (!this.requestBooking) {
            this.set();
        }
        this.requestBooking.distance = distance;
        this.calculateFare();
    }
    // static getDistance(): number {
    //     if (this.requestBooking)
    //         return this.requestBooking.distance;
    //     return 0;

    // }


    static getNewBookingId(length: number = 6): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    }

    static getCityFromAddress(address: string): string {

        if (address.toLocaleLowerCase().includes('mumbai'))
            return "Mumbai"

        return "Pune"

    }
    static getSelectedTolls() {
        if (this.requestBooking.booking.payment.toll === 320)
            return 'ExpressWay:320';
        if (this.requestBooking.booking.payment.toll === 365)
            return 'ExpressWay:320, VashiToll:45';
        if (this.requestBooking.booking.payment.toll === 640)
            return 'ExpressWay:320';

    }

 


    static async calculateFare() {
        console.log("called")
        this.requestBooking.booking.fromAddress = this.requestBooking.source
        this.requestBooking.booking.toAddress = this.requestBooking.destination
        this.requestBooking.booking.totalDistance = this.requestBooking.distance

        console.log("====== " + JSON.stringify(this.requestBooking.booking))
        const calFare = await calculatePayment(this.requestBooking.booking)
        console.log("p====== " + JSON.stringify(calFare))
        if (calFare) {
            this.requestBooking.booking.payment.baseFare = calFare.baseFare;
            this.requestBooking.booking.payment.toll = calFare.toll
            this.requestBooking.booking.payment.parking = calFare.parking
            this.requestBooking.booking.payment.extraKms = calFare.extraKms
            this.requestBooking.booking.payment.extraKmCharges = calFare.extraKmCharges
            this.requestBooking.booking.payment.gstFare = calFare.gstFare
            this.requestBooking.booking.payment.totalFare = calFare.totalFare
            this.requestBooking.booking.payment.extraPickorDrop = calFare.extraPickorDrop
            this.requestBooking.booking.payment.extraPickorDropCharges = calFare.extraPickorDropCharges
        }



    }

    static set() {
        if (!this.requestBooking) {
            // Initialize the requestBooking if it doesn't exist
            this.requestBooking = {
                booking: {
                    _id: '',
                    userId: '',
                    bookingId: '',
                    phone: '',
                    name: '',
                    fromCity: '',
                    toCity: '',
                    bookingDate: '',
                    createdDate: new Date().toISOString(), // or set as needed
                    fromAddress: '',
                    toAddress: '',
                    selectedTolls: '',
                    totalDistance: 0,
                    returnRide: false,
                    numberOfBigBags: 0,
                    numberOfPassengers: 0,
                    notification: {
                        confirmationMsg: false,
                        cabDetailsMsg: false,
                        driverMsg: false,
                    },
                    carId: '',
                    driverId: '',
                    partnerId: '',
                    referenceId: '',
                    couponCode: '',
                    payment: {
                        paymentId: '',
                        paymentType: '',
                        AdvancePayment: 0,
                        baseFare: 0,
                        gstFare: 0,
                        toll: 0,
                        parking: 0,
                        waitingCharges: 0,
                        extraKmCharges: 0,
                        totalFare: 0,
                        extraKms: 0,
                        waitingTime: 0,
                        carrierCharges: 0,
                        extraPickorDrop: 0,
                        extraPickorDropCharges: 0,
                        discount: 0,
                        created: new Date().toISOString(),
                        status: 'NOT_PAID',
                    },
                    ride: {
                        startTime: 'NA',
                        endTime: 'NA',
                        rideOtp: '0000',
                        customerRating: '0',
                        driverRating: '0',
                        commentByCustomer: 'NA',
                        commentByDriver: 'NA',
                    },
                    status: 'NEW',
                },
                source: '',
                destination: '',
                distance: 0,
                bookingDate: '',
                bookingTime: '',
            };
        }
    }









}
