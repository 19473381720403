// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 8px 10px 8px 0px;
  width: 95%;
  height: 100px;
}

.text-container {
  flex: 1 1;
  padding-right: 16px;
}

.header {
  margin: 0;
  font-size: 24px;
}

.text {
  margin: 0;
  font-size: 16px;
}

.image-container {
  flex: 0.7 1;
}

.image {
  width: 150px;
  height: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/booking/CSSStyle/CardImageRight.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,wBAAwB;EACxB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,SAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".card-container {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 16px;\r\n  background-color: #ffffff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  margin: 8px 10px 8px 0px;\r\n  width: 95%;\r\n  height: 100px;\r\n}\r\n\r\n.text-container {\r\n  flex: 1;\r\n  padding-right: 16px;\r\n}\r\n\r\n.header {\r\n  margin: 0;\r\n  font-size: 24px;\r\n}\r\n\r\n.text {\r\n  margin: 0;\r\n  font-size: 16px;\r\n}\r\n\r\n.image-container {\r\n  flex: 0.7;\r\n}\r\n\r\n.image {\r\n  width: 150px;\r\n  height: 100%;\r\n  object-fit: cover;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
