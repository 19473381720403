import React, { useState } from "react";
import { Button, TextField, MenuItem, Slide, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import SourceDestination from "../components/booking/common/SourceDestination";
import { AppContext } from "../components/services/AppContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EstimatedFare from "../components/booking/common/EstimatedFare";
import "../styles/commonStyle.css";
import CountryCodePicker from "../components/booking/common/CountryCodePicker";

const libraries: "places"[] = ["places"];

interface Form2Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
}

const NewBookingForm2: React.FC<Form2Props> = ({
  formValues,
  handleInputChange,
  handleNext,
  handleBack,
}) => {
  const { isLoaded } = useLoadScript({
   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "", // Use environment variable for API key
   // googleMapsApiKey : "AIzaSyBP3vgSopTFtFYGDGpM3ojSIAA-pzjAnaw",
    libraries: libraries,
  });

  //const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
    AppContext.travelDate = event.target.value;
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
    AppContext.travelTime = event.target.value;
  };

  const handlePhoneChange = (phoneNumber: string) => {
    const phoneChangeEvent = {
      target: { name: "phone", value: phoneNumber },
    } as React.ChangeEvent<HTMLInputElement>;
    // console.log("phoneChangeEvent  " + JSON.stringify(phoneChangeEvent))
    handleInputChange(phoneChangeEvent);
  };

  const generateTimeOptions = () => {
    const times = [];
    const formatTime = (hour: number, minute: number) => {
      return ` ${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    };

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        times.push(formatTime(hour, minute));
      }
    }
    return times;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e);
  };

  const handleNextClicked = () => {
    // console.log("handleNextClicked")
    if (!formValues.name) {
      setSnackbarMessage("Please enter your name.");
      setSnackbarOpen(true);
      return;
    }
    if (formValues.phone.length < 8) {
      setSnackbarMessage("Please enter your phone number.");
      setSnackbarOpen(true);
      return;
    }
    if (!formValues.bookingDate) {
      setSnackbarMessage("Please select booking date.");
      setSnackbarOpen(true);
      return;
    }
    if (!formValues.bookingTime) {
      setSnackbarMessage("Please select booking time");
      setSnackbarOpen(true);
      return;
    }
    if (!formValues.numberOfPassengers) {
      setSnackbarMessage("Please select number of passengers");
      setSnackbarOpen(true);
      return;
    }
    if (!formValues.numberOfBigBags) {
      setSnackbarMessage("Please select number of bags bigs.");
      setSnackbarOpen(true);
      return;
    }

    handleNext();
  };

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <SourceDestination
            source={formValues.source}
            destination={formValues.destination}
          />
        </Grid>

        <EstimatedFare />

        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            label="Enter Your Name"
            fullWidth
            name="name"
            value={formValues.name}
            onChange={handleNameChange}
            required
            variant="outlined"
            sx={{ bgcolor: "white", borderRadius: 1, mt: 3, mb: 0 }}
          />
        </Grid>

        <CountryCodePicker
          phoneText={formValues.phone}
          onChange={handlePhoneChange}
        />
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            type="date"
            name="bookingDate"
            label="Booking Date"
            fullWidth
            value={AppContext.travelDate}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split("T")[0], // today onwards
            }}
            variant="outlined"
            required
            sx={{ bgcolor: "white", borderRadius: 1, mt: 2 }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            name="bookingTime"
            label="Booking Time"
            fullWidth
            value={AppContext.travelTime}
            onChange={handleTimeChange}
            variant="outlined"
            required
            sx={{ bgcolor: "white", borderRadius: 1, mt: 2, textAlign: "left" }}
          >
            {generateTimeOptions().map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            label="Number Of Passengers"
            fullWidth
            name="numberOfPassengers"
            value={formValues.numberOfPassengers.toString()}
            onChange={handleInputChange}
            required
            //type="number"
            sx={{ bgcolor: "white", borderRadius: 1, mt: 2, textAlign: "left" }}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <TextField
            select
            label="Number Of Big Bags"
            fullWidth
            name="numberOfBigBags"
            value={formValues.numberOfBigBags.toString()}
            onChange={handleInputChange}
            required
            //type="number"
            sx={{ bgcolor: "white", borderRadius: 1, mt: 2, textAlign: "left" }}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
          </TextField>
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              "& .MuiSnackbarContent-root": {
                maxWidth: "400px",
                width: "100%",
              },
              position: "fixed", // Keeps it always visible
              top: "auto", // Reset top
              bottom: "auto", // Reset bottom
              left: "50%",
              zIndex: 1300,
              transform: "translateX(-50%)",
              [theme.breakpoints.up("md")]: {
                top: "100px", // Keeps it always on top
              },
              [theme.breakpoints.down("sm")]: {
                maxWidth: "90%",
                width: "90%",
                top: "100px", // Ensures it's visible on mobile
              },
            }}
            aria-live="assertive"
          >
            <Alert onClose={handleCloseSnackbar} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>

        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            className="back-button"
          >
            Back
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextClicked}
            className="primary-button"
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <br></br>
      <br></br>
    </div>
  );
};

export default NewBookingForm2;
