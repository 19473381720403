// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* commonStyles.css */


.customButton {
  background-color: #25d366;  /* Set background color to #25d366 */
  color: white;
  /* width: 100%; */
  margin: 10px 10px;

  /* Smooth transition for hover effect */
  transition: background-color 0.3s ease;
}

/* Hover effect */
.customButton:hover {
  background-color: #128c44;  /* Darker green on hover */
}`, "",{"version":3,"sources":["webpack://./src/styles/CommonCSS.css"],"names":[],"mappings":"AAAA,qBAAqB;;;AAGrB;EACE,yBAAyB,GAAG,oCAAoC;EAChE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;;EAEjB,uCAAuC;EACvC,sCAAsC;AACxC;;AAEA,iBAAiB;AACjB;EACE,yBAAyB,GAAG,0BAA0B;AACxD","sourcesContent":["/* commonStyles.css */\r\n\r\n\r\n.customButton {\r\n  background-color: #25d366;  /* Set background color to #25d366 */\r\n  color: white;\r\n  /* width: 100%; */\r\n  margin: 10px 10px;\r\n\r\n  /* Smooth transition for hover effect */\r\n  transition: background-color 0.3s ease;\r\n}\r\n\r\n/* Hover effect */\r\n.customButton:hover {\r\n  background-color: #128c44;  /* Darker green on hover */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
