import { AppContext } from './AppContext';
import { GET_DRIVERS } from './queries';
import { Driver } from "../models/booking";
import client from "./apolloClient";




let _drivers: Driver[] | null;
export const getDrivers = async (refresh: boolean): Promise<Driver[] | null> => {

    if (_drivers?.length == undefined || refresh === true) {
        _drivers = await fetchData();
    }

    return _drivers;
}


export const getActiveDrivers = async (refresh: boolean): Promise<Driver[] | undefined> => {

    if (_drivers?.length == undefined || refresh === true) {
        _drivers = await fetchData();
    }

    return _drivers?.filter(item => item.status === "ACTIVE");
}

export const getDriver =(driverId: string): Driver => {

    getDrivers(true);

    let driver = _drivers?.find(driver => driver?.userId === driverId);
    if (driver === undefined) {
        return { _id: '', userId: '', name: 'TBD....', phone: '', status: '' }
    }

    return driver;
}


export const fetchData = async (): Promise<Driver[] | null> => {

    try {
        const { data } = await client.query({
            query: GET_DRIVERS,
            
        });
        let drivers = data?.getDrivers?.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });

        return [...drivers]
        // return data?.getDrivers;
    } catch (error) {
        console.log('Error fetching drivers:', error);
        return null;
    }
};

