import React from "react";
import { Typography, Card, CardContent, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Booking } from "../../models/booking";


interface BookingDetailsProps {
  booking: Booking | undefined;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking }) => {

  const convertUTCToIST = (utcDate: string) => {
    const date = new Date(utcDate)
    // Return formatted IST time
    const ISTDate = date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) + " " + "IST"
    return ISTDate;
  };


  return (
    <Card sx={{ maxWidth: "lg", mx: "auto", p: 2, boxShadow: 3, borderRadius: 2, backgroundColor: "white", mt: 2, borderTop: 0, display: "flex", justifyContent: "center" }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom align="left" sx={{ fontWeight: "bold" }}>
          Booking Details
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Booking ID:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.bookingId}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Name:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.name}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Phone:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.phone}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">From:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.fromAddress}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">To:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.toAddress}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Created On</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.createdDate ? new Date(booking.createdDate).toLocaleString() : "N/A"}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Booking Date:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.bookingDate ? convertUTCToIST(booking?.bookingDate) : "N/A"}</Typography></Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 4 }}><Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">Return Ride:</Typography></Grid>
            <Grid size={{ xs: 8 }}><Typography variant="body2" align="left">{booking?.returnRide ? "Yes" : "No"}</Typography></Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BookingDetails;
