

//import { AppContext } from './AppContext';
import { CREATE_NEW_BOOKING,  } from './mutation';
import { GET_BOOKINGS_BY_USERID ,CALCULATE_FARE} from './queries';
import client from "./apolloClient";
import { Booking, BookingInput, Payment } from "../models/booking";
import { AppContext } from './AppContext'

export const createBooking = async (newBooking: BookingInput): Promise<Booking | null> => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_NEW_BOOKING,
      variables: { newBooking },
      context: {
        headers: {
          // Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      }
    });
    // refreshAppContext(data?.createBooking);
    return data?.createBooking;
  } catch (error) {
    // console.error('Error fetching create booking:', error);
    console.log("Error fetching create booking:" + error)
    return null;
  }
}

export const getBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | null> => {
  if (AppContext.bookings && AppContext.bookings.length > 0) {
    return AppContext.bookings;
  }
  if (userId === undefined) {
    return null;
  }
  try {
    const { data } = await client.query({
      query: GET_BOOKINGS_BY_USERID,
      variables: { userId },
      context: {
        headers: {
          // Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      }
    });
    //await refreshAppContext(data?.getBookingsByUserId);
    return data?.getBookingsByUserId;
  } catch (error) {
    console.log('Error fetching getBookingsByUserId:', error);
    return null;
  }
}

export const getOngoingBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {

  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => item.status === 'STARTED').sort((a, b) => { return a.bookingDate.localeCompare(b.bookingDate) });
  return filtered;
}

export const getNextBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {
  // const today = new Date();
  // today.setHours(0, 0, 0, 0)
  // let yesterday = new Date();
  // yesterday.setDate(today.getDate() - 2);

  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => (item.status === 'CONFIRMED' || item.status === 'STARTED' || item.status === 'NEW')).sort((a, b) => { return a.bookingDate.localeCompare(b.bookingDate) });
  return filtered;
}

export const getFinishedBookingsByUserId = async (userId: string | undefined, ): Promise<Booking[] | undefined> => {
  const bookings = await getBookingsByUserId(userId);
  const filtered = bookings?.filter(item => item.status === 'FINISHED' || item.status === 'CANCELLED').sort((a, b) => { return b.bookingDate.localeCompare(a.bookingDate) });
  return filtered;
}

function removeTypename(obj: any): any {

  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item));
  }

  if (obj && typeof obj === 'object') {
    const { __typename, ...rest } = obj;

    return Object.keys(rest).reduce((acc: { [key: string]: any }, key: string) => {
      acc[key] = removeTypename(rest[key]);
      return acc;
    }, {});
  }
  return obj;
}
export const calculatePayment = async (booking: Booking): Promise<Payment | null> => {
  try {

    // console.log(booking.payment);
    console.log("in calculatePayment = " + JSON.stringify(booking))

    const { data, errors } = await client.query({
      query: CALCULATE_FARE,
      variables: { booking },
      context: {
        headers: {
          //  Authorization: AppContext.loggedAuthToken ? `Bearer ${AppContext.loggedAuthToken}` : '',
        },
      },

    });
    if (errors) {
      console.log(errors)
    }
    return data?.calculateFare;
  } catch (error) {
    console.log('Error fetching calculate Payment :', error);
    // if (error.graphQLErrors) {
    //   console.log('GraphQL Errors:', error.graphQLErrors);
    // }
    // if (error.networkError) {
    //   console.log('Network Error:', error.networkError);
    // }
    // if (error.message) {
    //   console.log("Error Message:", error.message);
    // }
    return null;
  }
}





// const sampleBookings = [
//   {
//     bookingId: '1',
//     name: 'John Doe',
//     phone: '123-456-7890',
//     fromCity: 'New York',
//     toCity: 'Los Angeles',
//     bookingDate: '2024-04-15',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-10',
//     fromAddress: '123 Main St, New York  ',
//     toAddress: '456 Oak St, Los Angeles 456 Oak St, Los Angeles ',
//     returnRide: 'No',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '1', carNumber: 'ABC123', carType: 'SUV', carModel: 'Toyota Highlander' },
//     driver: { driverId: '1', name: 'Mike Smith', phone: '987-654-3210' },
//     payment: { paymemtId: '1', paymentType: 'Credit Card', baseFare: 50, gstFare: 5, toll: 10, parking: 5, waitingCharges: 0, extraKmCharges: 0, totalFare: 70, extraKms: 0, waitingTime: 0, created: '2024-04-13', status: true },
//     ride: { officeStart: '2024-04-13', startTime: '10:00 AM', endTime: '12:00 PM', rideOtp: '1234', driverRating: 4.5, totalhrs :2 , customerRating: 0 }
//   },
//   {
//     bookingId: '2',
//     name: 'Alice Johnson',
//     phone: '456-789-0123',
//     fromCity: 'Chicago',
//     toCity: 'San Francisco',
//     bookingDate: '2024-04-15',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-12',
//     fromAddress: '789 Elm St, Chicago',
//     toAddress: '321 Pine St, San Francisco',
//     returnRide: 'Yes',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '2', carNumber: 'XYZ789', carType: 'Sedan', carModel: 'Honda Accord' },
//     driver: { driverId: '2', name: 'Emily Brown', phone: '012-345-6789' },
//     payment: { paymemtId: '2', paymentType: 'Cash', baseFare: 60, gstFare: 16, toll: 15, parking: 7, waitingCharges: 0, extraKmCharges: 0, totalFare: 98, extraKms: 0, waitingTime: 0, created: '2024-04-15', status: true },
//     ride: { officeStart: '2024-04-15', startTime: '09:00 AM', endTime: '01:00 PM', rideOtp: '5678', driverRating: 4.8,  totalhrs :4 , customerRating: 0 }
//   },
//   // Add more sample bookings here...
//   {
//     bookingId: '3',
//     name: 'Nick Fury',
//     phone: '453-456-7890',
//     fromCity: 'Brighton',
//     toCity: 'Berlin',
//     bookingDate: '2024-02-5',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-10',
//     fromAddress: '123 Main St, New York',
//     toAddress: '456 Oak St, Los Angeles',
//     returnRide: 'No',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '1', carNumber: 'ABC123', carType: 'SUV', carModel: 'Fortuner Sport' },
//     driver: { driverId: '1', name: 'Mike Smith', phone: '987-654-3210' },
//     payment: { paymemtId: '1', paymentType: 'UPI', baseFare: 20, gstFare: 5, toll: 10, parking: 5, waitingCharges: 0, extraKmCharges: 0, totalFare: 40, extraKms: 0, waitingTime: 0, created: '2024-04-13', status: true },
//     ride: { officeStart: '2024-04-13', startTime: '10:00 AM', endTime: '12:00 PM', rideOtp: '1234', driverRating: 4.5, totalhrs :2 , customerRating: 0 }
//   },
//   {
//     bookingId: '4',
//     name: 'Mikel Doe',
//     phone: '123-456-7890',
//     fromCity: 'Long Beach',
//     toCity: 'Canon City',
//     bookingDate:'2024-04-13',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-10',
//     fromAddress: '123 Main St, New York',
//     toAddress: '456 Oak St, Los Angeles 456 Oak St, Los Angeles',
//     returnRide: 'No',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '1', carNumber: 'ABC123', carType: 'SUV', carModel: 'Toyota Highlander' },
//     driver: { driverId: '1', name: 'Mike Smith', phone: '987-654-3210' },
//     payment: { paymemtId: '1', paymentType: 'Online', baseFare: 70, gstFare: 5, toll: 10, parking: 5, waitingCharges: 0, extraKmCharges: 0, totalFare: 90, extraKms: 0, waitingTime: 0, created: '2024-04-13', status: true },
//     ride: { officeStart: '2024-04-13', startTime: '10:00 AM', endTime: '12:00 PM', rideOtp: '1234', driverRating: 4.5, totalhrs :2 ,  customerRating: 0 }
//   },
//   {
//     bookingId: '5',
//     name: 'Michael Johnson',
//     phone: '987-654-3210',
//     fromCity: 'Houston',
//     toCity: 'Dallas',
//     bookingDate: '2024-04-30',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-18',
//     fromAddress: '456 Oak St, Houston',
//     toAddress: '789 Pine St, Dallas',
//     returnRide: 'No',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '5', carNumber: 'JKL456', carType: 'SUV', carModel: 'Ford Explorer' },
//     driver: { driverId: '5', name: 'Daniel Johnson', phone: '123-456-7890' },
//     payment: { paymemtId: '10', paymentType: 'Cash', baseFare: 90, gstFare: 7, toll: 12, parking: 8, waitingCharges: 0, extraKmCharges: 0, totalFare: 117, extraKms: 0, waitingTime: 0, created: '2024-04-20', status: true },
//     ride: { officeStart: '2024-04-20', startTime: '11:00 AM', endTime: '4:00 PM', rideOtp: '9012', driverRating: 4.7,  totalhrs :5 , customerRating: 0 }
//   },
//   {
//     bookingId: '6',
//     name: 'John Doe',
//     phone: '123-456-7890',
//     fromCity: 'Bostan',
//     toCity: 'Long Beach',
//     bookingDate: '2024-04-13',
//     bookingTime :'11:24 am',
//     createdDate: '2024-04-10',
//     fromAddress: '123 Main St, New York',
//     toAddress: '456 Oak St, Los Angeles',
//     returnRide: 'No',
//     notification: { confirmationMsg: true, cabDetailsMsg: true, driverMsg: false },
//     car: { carId: '1', carNumber: 'ABC123', carType: 'SUV', carModel: 'Toyota Highlander' },
//     driver: { driverId: '1', name: 'Mike Smith', phone: '987-654-3210' },
//     payment: { paymemtId: '1', paymentType: 'Credit Card', baseFare: 50, gstFare: 15, toll: 10, parking: 5, waitingCharges: 0, extraKmCharges: 0, totalFare: 80, extraKms: 0, waitingTime: 0, created: '2024-04-13', status: true },
//     ride: { officeStart: '2024-04-13', startTime: '10:00 AM', endTime: '12:00 PM', rideOtp: '1234', driverRating: 4.5, totalhrs :2 ,  customerRating: 0 }
//   },

// ];


// export const getMyBookings = (): Booking[] => {

//   return sampleBookings;
// }