import React, { memo } from 'react';
import './../CSSStyle/CardImageRight.css';
import  CabFeatures from './CabFeatures'
interface Props {
    header: string;
    text: string;
    imageSource: string; 
}

const CardImageRight: React.FC<Props> = ({ header, text, imageSource }) => {
    return (
        <div className="card-container">
            <div className="text-container">
                <h2 className="header">{header}</h2>
                <p className="text">{text}</p>
            </div>
           
            <div className="image-container">
                <img src={imageSource} alt={header} className="image" />
            </div>
        </div>
    );
};

export default memo(CardImageRight);
