// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselWs from '../components/CarouselWs';
import GrpIconButton from '../components/GrpIconButton';


const ExtraSafetyMeasures: React.FC = () => {

  return (
    <div>
      <CarouselWs></CarouselWs>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Extra Safety Measures for Women travelers
      </Typography>
      <Typography textAlign="left" variant="body1">
        <br />
        We have installed KENT CamEye (Next-Gen Car Dashboard Camera) in our all cabs.
        <br /><br />
        Safe and Reliable Journey for Mumbai Airport Drop and Pickup.
        <br /><br />
        Locate our cab in case there is emergency using GPS tracking capabilities.
        <br /><br />
        We ensure the security and safety of our passengers, on-road as well as when it is parked.
        <br /><br />
        Make sure that the driver of our cab isn’t on the phone all the time. Also see if they are not misbehaving with the passenger. If there are any conflicts at the end of the day, we can view video recordings to ensure that nothing of that sort happens ever again.
      </Typography>
      <br />
      <GrpIconButton name={'BOOK RIDE NOW'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
      <br /><br />
    </div>
  );
};

export default ExtraSafetyMeasures;
