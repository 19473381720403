
import { gql } from '@apollo/client';



export const CREATE_NEW_BOOKING = gql`
mutation CreateBooking($newBooking: BookingInput!) {
  createBooking(newBooking: $newBooking) {
    _id
    userId
    name
    bookingId
    phone
    createdDate
    bookingDate
    fromCity
    toCity
    fromAddress
    toAddress
    selectedTolls
    totalDistance
    returnRide
    numberOfPassengers
    numberOfBigBags
    carId
    driverId
    referenceId
    couponCode
    ride {
      startTime
      endTime
      rideOtp
      customerRating
      driverRating
      commentByCustomer
      commentByDriver
    }
    payment {
      paymentId
      paymentType
      AdvancePayment
      baseFare
      gstFare
      toll
      parking
      waitingCharges
      extraKmCharges
      totalFare
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
      created
      status
    }
    partnerId
    notification {
      confirmationMsg
      cabDetailsMsg
      driverMsg
    }
    status
  }
}
`


export const CREATE_INVOICE = gql`
mutation CreateInvoice($newInvoice: InvoiceInput!) {
  createInvoice(newInvoice: $newInvoice) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
    payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`

export const UPDATE_INVOICE = gql`

mutation UpdateInvoice($newInvoice: InvoiceInput!) {
  updateInvoice(newInvoice: $newInvoice) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
     payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`
export const UPDATE_INVOICE_NUMBER= gql`
mutation UpdateInvoiceNumber {
  updateInvoiceNumber {
    toll {
      tollName
      tollPrice
    }
    lastInvoiceNumber
  }
}
`

