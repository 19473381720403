import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';

const ads1 = "/images/SQ_why/SQ_why-1.jpg";
const ads2 = "/images/SQ_why/SQ_why-2.jpg";
const ads3 = "/images/SQ_why/SQ_why-3.jpg";
const ads4 = "/images/SQ_why/SQ_why-4.jpg";
const ads5 = "/images/SQ_why/SQ_why-5.jpg";

const CarouselWhy: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box sx={{ width: '100%', margin: '0 auto', padding: '0px 0px 0px 0px' }}>
      <Slider {...settings}>
        <div>
          <img
            src={ads1}
            alt="Slide 1"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads2}
            alt="Slide 2"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads3}
            alt="Slide 3"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads4}
            alt="Slide 3"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
        <div>
          <img
            src={ads5}
            alt="Slide 3"
            style={{ width: '100%', height: 'auto', borderRadius: '10px'  }}
            loading='lazy'
          />
        </div>
      </Slider>
    </Box>
  );
};

export default CarouselWhy;
