import React from 'react';
import { IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

interface PhoneIconProps {
    phone: string;
}


const PhoneDriverIcon: React.FC<PhoneIconProps> = ({ phone }) => {
    const onPhoneIconClick = () => {
        const updatedPhone = phone.replace(/\D/g, '');
        window.location.href = `tel:${updatedPhone}`;
    };

    return (
        <IconButton size="small" color="primary" onClick={onPhoneIconClick} sx={{ ml: 1 }}>
            <PhoneIcon />
        </IconButton>
    );
};

export default PhoneDriverIcon;
