import React from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Define the Footer component using TypeScript
const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: '#25d366',
        color: 'white',
      }}
    >
      <Grid>        
        <Grid container spacing={4}>
          {/* Navigation Links */}
          <Grid size={{ xs: 12, sm: 4, md: 4 }}>
            <Typography variant="h6" gutterBottom>
              Get our User App
            </Typography>
            <Link
              href="https://play.google.com/store/apps/details?id=in_.greenrides.grpuser" // Replace with your app's URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Download on Google Play"
                width="200"
                loading='lazy'
              />
            </Link>
            <br />
            {/* <Link
              href="https://apps.apple.com/app/id123456789" // Replace with your app's App Store URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="Download on the App Store"
                width="200"
              />
            </Link> */}
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 4 }}>
            <Typography variant="h6" gutterBottom>
              User Links
            </Typography>
            <Box>
              <Link href="/book-your-green-ride" color="inherit" underline="hover">
                Book a Mumbai Airport Ride
              </Link>
              <br />
              <Link href="/why-grp" color="inherit" underline="hover">
                Why Green Rides Pune?
              </Link>
              <br />
              <Link href="/why-byd" color="inherit" underline="hover">
                Why BYD E6?
              </Link>              
              <br />
              <Link href="/extra-safety" color="inherit" underline="hover">
                Extra Safety Measures for Women Travelers
              </Link>
              <br />
              <Link href="/refer-friend" color="inherit" underline="hover">
                Refer a Friend!
              </Link>
              <br />
              <Link href="/book-now" color="inherit" underline="hover">
                Book Now
              </Link>
              <br />
              <Link href="/contactus" color="inherit" underline="hover">
                Contact Us
              </Link>
              <br />
              <Link href="/termsandconditions" color="inherit" underline="hover">
                Terms &amp; Conditions
              </Link>
              <br />
              <Link href="/privacypolicy" color="inherit" underline="hover">
                Privacy Policy
              </Link>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 4 }}>
            <Typography variant="h6" gutterBottom>
              Other Links
            </Typography>
            <Box>
              <Link href="/grp-business-users" color="inherit" underline="hover">
                Business/Corporate Users
              </Link>
              <br />
              <Link href="/grp-partner-users" color="inherit" underline="hover">
                Green Rides Pune Partners
              </Link>
              <br />
              <Link href="/grp-drivers" color="inherit" underline="hover">
                Green Rides Pune Drivers
              </Link>
            </Box>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <Link href="https://www.facebook.com/share/18QXLepPS5/?mibextid=wwXIfr" target="_blank" color="inherit" underline="none">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                  alt="Facebook"
                  width="30"
                  style={{ marginRight: '10px' }}
                  loading='lazy'
                />
              </Link>
              {/* <Link href="https://www.twitter.com" target="_blank" color="inherit" underline="none">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                  alt="Twitter"
                  width="30"
                  style={{ marginRight: '10px' }}
                />
              </Link> */}
              <Link href="https://www.instagram.com/greenridespune/" target="_blank" color="inherit" underline="none">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733558.png"
                  alt="Instagram"
                  width="30"
                  style={{ marginRight: '10px' }}
                  loading='lazy'
                />
              </Link>
              {/* <Link href="https://www.linkedin.com" target="_blank" color="inherit" underline="none">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733561.png"
                  alt="LinkedIn"
                  width="30"
                />
              </Link> */}
            </Box>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Box sx={{ textAlign: 'center', mt: 1 }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Green Rides Pune. All Rights Reserved.
          </Typography>
        </Box>

      </Grid>
    </Box >
  );
};

export default Footer;
