import React, { memo } from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { RequestBookingService } from '../../services/RequestBookingService'


const EstimatedFare: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Grid container spacing={1} alignItems="center">
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left" sx={{ fontWeight: 'bold', color: 'black' }} >Total Distance</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                {RequestBookingService.getRequestBooking().booking.payment.extraKms + 150} Km
              </Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left">Base Fare (150 km)</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right">
                ₹{RequestBookingService.getRequestBooking().booking.payment.baseFare}
              </Typography>
            </Grid>

            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left">
                Extra Km Charges ({RequestBookingService.getRequestBooking().booking.payment.extraKms} x ₹27)
              </Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right">
                ₹{RequestBookingService.getRequestBooking().booking.payment.extraKmCharges}
              </Typography>
            </Grid>

            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left">GST (5%)</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right">
                ₹{RequestBookingService.getRequestBooking().booking.payment.gstFare}
              </Typography>
            </Grid>

            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left">Toll (Expressway)</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right">
                ₹{RequestBookingService.getRequestBooking().booking.payment.toll}
              </Typography>
            </Grid>

            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left">Parking</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right">
                ₹{RequestBookingService.getRequestBooking().booking.payment.parking}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left" sx={{ fontWeight: 'bold', color: 'green' }}>Discount (Online Booking)</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right" sx={{ fontWeight: 'bold', color: 'green' }}>
                ₹{RequestBookingService.getRequestBooking().booking.payment.discount}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="left" sx={{ fontWeight: 'bold', color: 'green' }}>Estimated Fare</Typography>
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 6 }}>
              <Typography align="right" sx={{ fontWeight: 'bold', color: 'green' }}>
                ₹{RequestBookingService.getRequestBooking().booking.payment.totalFare}
              </Typography>
            </Grid>        
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default memo(EstimatedFare);
