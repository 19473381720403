// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselPartner from '../components/CarouselPartner';
import GrpIconButton from '../components/GrpIconButton';


const GrpPartnerUser: React.FC = () => {
  return (
    <div>
      <CarouselPartner></CarouselPartner>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Join Our Green Initiative!
      </Typography>
      <br />
      <Typography textAlign="left" variant="h6">
        Be a part of our mission for a cleaner, healthier future! By choosing eco-friendly practices, we can reduce our carbon footprint and make a lasting impact on the environment.
        <br /><br></br>🌎 Sustainable Choices
        <br></br>🚗 Eco-Conscious Transportation
        <br></br>♻️ Reduce, Reuse, Recycle
        <br />
        <br></br>Together, we can drive change! 💚 #GreenRidesPune #GoGreen #SustainableFuture
        <br></br>
      </Typography>
      <br></br>
      <Typography textAlign="left" variant="h6">
        Please drop a message on WhatsApp (918600572727) or contact@greenrides.in.
      </Typography>
      <br />
      <GrpIconButton name={'BOOK RIDE NOW'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>
      <br /><br />
    </div>
  );
};

export default GrpPartnerUser;
