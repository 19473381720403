// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselDriver from '../components/CarouselDriver';

const GrpDriver: React.FC = () => {

  return (
    <div>
      <CarouselDriver></CarouselDriver>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        We need experienced cab drivers
      </Typography>
      <Typography textAlign="left" variant="h5">
        Requirement,
      </Typography>
      <Typography textAlign="left" variant="h6">
        Basic Qualification - 10th Pass
        <br />
        Driving Experience 10+ years 
        <br />
        Non-Somking & Non Drinking
        <br />
        Police Verification is must before joining.
      </Typography>
      <br />
      <br />
      <Typography textAlign="left" variant="h5">
        Please send the following documents to WhatsApp (918600572727) or contact@greenrides.in,
      </Typography>
      <Typography textAlign="left" variant="h6">
        Driving License
        <br />
        Aadhar Card
      </Typography>
      <br />
    </div>
  );
};

export default GrpDriver;
