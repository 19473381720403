import React, { useState, useEffect } from "react";
import { Button, Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, SelectChangeEvent, IconButton, Divider, } from "@mui/material";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from '@mui/icons-material/BookOnline';
import Grid from '@mui/material/Grid2';
import { RequestBookingService } from './services/RequestBookingService'
import { AppContext } from './services/AppContext'
import { parsePhoneNumberWithError, parsePhoneNumber } from 'libphonenumber-js';
import { getTerminalT2Address } from './services/savedAddress'
import { createOrAddUserDetails } from "./services/createOrAddUserDetailsService"
import EstimatedFare from '../components/booking/common/EstimatedFare'
import ProgressMessage from '../components/booking/common/ProgressMessage'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '@mui/material/Alert';
import { createNewBookingService } from './services/createNewBookingService'
import { createOrGetUser } from './services/createOrGetUserService'
import { getDriver, getDrivers } from './services/driverService'
import { Booking, Driver } from './models/booking'
import { sendBookingMsgToDriver, sendBookingMsgToUser } from './services/BookingService'
import WhatsAppDriverIcon from "./booking/components/fields/WhatsAppDriverIcon"
import PhoneDriverIcon from "./booking/components/fields/PhoneDriverIcon"
import { UpdateTotalRides } from "./services/userService"

const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
const libraries: ("places")[] = ["places"];

interface BookNowMessageProps {
    fromLocation: string;
    toLocation: string;
    fare: string;
    driverId: string;
    carId: string;
    inMinutes: string
}

const BookNowButton: React.FC<BookNowMessageProps> = ({ fromLocation, toLocation, carId, fare, driverId, inMinutes }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleApi,
        libraries
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState<string>("");
    const [whatsappNumber, setWhatsappNumber] = useState<string>("+91");
    const [formattedNumber, setFormattedNumber] = useState<string>("");
    const [selectedTime, setSelectedTime] = useState<string>("0");
    const [address, setAddress] = useState<string>("");
    const [addressAutocomplete, setAddressAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [progressMessage, setProgressMessage] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("+91");
    const [showEstimatedFare, setShowEstimatedFare] = useState<boolean>(false);
    const [showCompletionMsg, setShowCompletionMsg] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [driver, setDriver] = useState<Driver>();


    useEffect(() => {
        if (driverId) {
            getDriverData();
        }
    }, [driverId]);


    const getDriverData = async () => {
        try {
            await getDrivers(true)
            const driver = getDriver(driverId)

            if (driver) {
                setDriver(driver);
            }
        } catch (error) {
            console.log("Error fetching driver data:", error);
        }
    };


    const handleOpen = () => {
        setOpen(true);
        AppContext.grpLastMinuteBooking = true
        document.body.style.overflow = 'hidden'; // Prevent scrolling
    };

    const handleClose = () => {
        AppContext.grpLastMinuteBooking = false
        RequestBookingService.setToInitialState()
        setOpen(false);
        setShowEstimatedFare(false)
        setAddress('')
        setName('')
        setWhatsappNumber('')
        setShowCompletionMsg('')
        document.body.style.overflow = 'auto'; // Restore scrolling
        setErrorMessage("");
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setAddress(value);
        setErrorMessage("");

        if (!value.trim()) {
            setShowEstimatedFare(false);
        }
    };

    const handleAddressPlaceChanged = async () => {
        const place = addressAutocomplete?.getPlace();
        if (place) {
            const newAddress = place.formatted_address || place.name || "";
            setAddress(newAddress);
            if (toLocation === "Pune") {
                RequestBookingService.setDestination(place)
                RequestBookingService.setSource(getTerminalT2Address())
            }
            else {
                RequestBookingService.setSource(place)
                RequestBookingService.setDestination(getTerminalT2Address())
            }

            setLoading(true)
            setProgressMessage("Estimating Fare...")

            await new Promise<void>((resolve) =>
                setTimeout(async () => {
                    await RequestBookingService.calculateDistance();
                    await RequestBookingService.calculateFare();
                    resolve();
                }, 1000)
            );

            setLoading(false)
            setShowEstimatedFare(true);
            setProgressMessage("");
        }
    };

    const handleTimeChange = (event: SelectChangeEvent<string>) => {
        setSelectedTime(event.target.value);
        setErrorMessage("");
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newPhone = event.target.value;

        if (newPhone === "" || newPhone === "+") {
            setWhatsappNumber("+");
            setFormattedNumber("");
            AppContext.phone = ''
            return;
        }
        newPhone = newPhone.replace(/[^0-9+]/g, "");

        if (newPhone.indexOf("+") !== 0) {
            newPhone = "+" + newPhone.replace(/\+/g, "");
        }

        setWhatsappNumber(newPhone);

        try {
            const parsedNumber = parsePhoneNumber(newPhone);
            if (parsedNumber && parsedNumber.isValid()) {
                const countryCode = `+${parsedNumber.countryCallingCode}`;
                const nationalNumber = parsedNumber.nationalNumber;
                setFormattedNumber(`(${countryCode})${nationalNumber}`);
                // console.log(`(${countryCode})${nationalNumber}`)
                AppContext.phone = `(${countryCode})${nationalNumber}`
                console.log("AppContext.phone " + AppContext.phone)
            } else {
                setFormattedNumber("");
            }
        } catch (error) {
            setFormattedNumber("");
        }
    };


    const handleSendRequest = async () => {

        AppContext.grpLastMinuteBooking = true
        if (!name) {
            setErrorMessage("Please enter your name.");
            return;
        }
        if (!RequestBookingService.getSource() && toLocation !== "Pune") {
            setErrorMessage("Please select your pickup address.");
            return;
        }
        if (!RequestBookingService.getDestination() && toLocation === "Pune") {
            setErrorMessage("Please select your drop address.");
            return;
        }

        if (selectedTime === "0") {
            setErrorMessage("Please select your pickup time.");
            return;
        }
        if (AppContext.phone.length < 8) {
            setErrorMessage("Please enter your phone number.");
            return;
        }
        setErrorMessage("");

        const user = await createOrGetUser(name, AppContext.phone)

        let data: Booking | null = null;
        setLoading(true)
        setProgressMessage("Please Wait....");

        const bookingData = {
            name: name,
            bookingDate: selectedTime,
            carId: carId,
            driverId: driverId
        };

        if (user) {
            setTimeout(async () => {
                await createOrAddUserDetails(user)
                data = await createNewBookingService(user, bookingData);
                if (data) {
                    setShowCompletionMsg("Your booking request has been accepted! A confirmation will be sent to your WhatsApp number shortly. You can now call the driver.");
                    setIsSuccess(true);
                    UpdateTotalRides(user ? user._id : '')
                    await sendBookingMsgToDriver(data, driverId)
                    await sendBookingMsgToUser(data)
                    RequestBookingService.setSource(null)
                    RequestBookingService.setDestination(null)
                    AppContext.phone = ""
                    AppContext.grpLastMinuteBooking = false
                    RequestBookingService.setToInitialState()
                    setLoading(false)
                }
                else {
                    setShowCompletionMsg("Booking could not be created. Please try again later.");
                    setIsSuccess(false);
                    setLoading(false)
                    AppContext.grpLastMinuteBooking = false
                }

            }, 1000)
        }

    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<WhatsAppIcon />}
                onClick={handleOpen}
                sx={{
                    color: "white",
                    "&:hover": { backgroundColor: "#1ebe57" },
                    textTransform: "none",
                    padding: "10px 20px",
                    fontSize: "20px",
                    WebkitTextFillColor: 'white',
                }}

            >
                BOOK THIS CAB NOW
            </Button>

            {open && (
                <>
                    {loading && <ProgressMessage progressMessage={progressMessage} />}
                    <Box
                        sx={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "white",
                            padding: 3,
                            marginBottom: 2,
                            borderRadius: 2,
                            boxShadow: 3,
                            width: "80%",
                            maxWidth: 400,
                            maxHeight: "90vh", // Prevents modal from exceeding screen height
                            overflowY: "auto", // Enables scrolling if content is too long
                            zIndex: 1000,
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                            sx={{ position: 'absolute', top: 2, right: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {showCompletionMsg ? (
                            <>
                                <Alert
                                    severity={isSuccess ? "success" : "error"}
                                    icon={isSuccess ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        color: isSuccess ? '#388e3c' : '#721c24',
                                        borderRadius: 2,
                                        fontSize: 18,
                                        width: "90%",
                                        marginBottom: 2,
                                        marginTop: 2
                                    }}
                                >
                                    {showCompletionMsg}
                                </Alert>
                                {isSuccess && (<>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left" gutterBottom sx={{ color: "green" }}  >
                                        Your Driver Details
                                    </Typography>
                                    <Divider sx={{ mb: 1 }} />

                                    <Typography variant="body1" textAlign="left" fontWeight="500" gutterBottom>
                                        <strong>Name:</strong> {driver?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1" textAlign="left" fontWeight="500" display="flex" alignItems="center">
                                        <strong>Phone:</strong>&nbsp; {driver?.phone || "N/A"}
                                        {driver?.phone && (
                                            <>
                                                <PhoneDriverIcon phone={driver.phone} />
                                                <WhatsAppDriverIcon phone={driver.phone} />
                                            </>
                                        )}
                                    </Typography>
                                </>)}

                            </>
                        ) : (
                                <>
                                    <Typography variant="h6" gutterBottom textAlign="center" fontWeight="bold">
                                        {fromLocation} To {toLocation}
                                    </Typography>

                                    <Typography variant="h6" gutterBottom textAlign="center" >
                                        Enter Booking Details
                                </Typography>
                                    <Grid container spacing={2}>
                                        <Grid size={{ xs: 12 }}>
                                            <TextField
                                                label="Your Name"
                                                placeholder="Enter Your Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                sx={{
                                                    marginTop: 2,
                                                    bgcolor: 'white',
                                                    borderRadius: 1,
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '50px',
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid size={{ xs: 12 }}>
                                            <Autocomplete
                                                onLoad={(autocomplete) => setAddressAutocomplete(autocomplete)}
                                                onPlaceChanged={handleAddressPlaceChanged}
                                            >
                                                <TextField
                                                    label={toLocation === "Pune" ? "Drop Address" : "Pick Up Address"}
                                                    placeholder={toLocation === "Pune" ? "Enter your drop address" : "Enter your pick up address"}
                                                    value={address}
                                                    onChange={handleAddressChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    onFocus={(e) => {
                                                        e.target.setAttribute("autocomplete", "new-password");
                                                    }}
                                                    sx={{
                                                        bgcolor: 'white',
                                                        borderRadius: 1,
                                                        '& .MuiOutlinedInput-root': {
                                                            height: '50px',
                                                        },
                                                    }}
                                                />
                                            </Autocomplete>
                                        </Grid>

                                        <Grid size={{ xs: 12 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>Pickup Time</InputLabel>
                                                <Select
                                                    value={selectedTime}
                                                    onChange={handleTimeChange}
                                                    label="Pickup Time"
                                                    required
                                                    sx={{
                                                        textAlign: 'left',
                                                        height: '50px',
                                                        paddingLeft: '7px',
                                                    }}
                                                >
                                                    <MenuItem value="0">Select</MenuItem>
                                                    <MenuItem value="Immediate">Immediate</MenuItem>
                                                    <MenuItem value="Need in 30 mins">Need in 30 mins</MenuItem>
                                                    <MenuItem value="Need in 1 hour">Need in 1 hour</MenuItem>
                                                    <MenuItem value="Need in 1.5 hours">Need in 1.5 hours</MenuItem>
                                                    <MenuItem value="Need in 2 hours">Need in 2 hours</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid size={{ xs: 12 }}>
                                            <TextField
                                                label="Your WhatsApp Number with country code"
                                                placeholder="Enter WhatsApp Number"
                                                value={whatsappNumber}
                                                onChange={(e: any) => handlePhoneInputChange(e)}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                sx={{
                                                    bgcolor: 'white',
                                                    borderRadius: 1,
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '50px',
                                                    },
                                                }}
                                            />
                                        </Grid>


                                        {showEstimatedFare && <EstimatedFare />}

                                        {errorMessage && (
                                            <Box display="flex" justifyContent="center" textAlign="center" width="100%">
                                                <Typography color="error" sx={{ mb: 2 }}>
                                                    {errorMessage}
                                                </Typography>
                                            </Box>
                                        )}

                                        <Grid size={{ xs: 12 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    backgroundColor: "#25D366",
                                                    color: "white",
                                                    "&:hover": { backgroundColor: "#1ebe57" }
                                                }}
                                                onClick={handleSendRequest}
                                            >
                                                Send Booking Request
                                        </Button>
                                        </Grid>

                                    </Grid>
                                </>
                            )}
                    </Box>
                </>
            )}

        </>
    );
};

export default BookNowButton;
