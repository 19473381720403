import { Link, Typography } from '@mui/material';
import React from 'react';
import CarouselBusiness from '../components/CarouselBusiness';


const GrpAppQRCode: React.FC = () => {

  return (
    <div>
      <br />
      <Typography textAlign="left" variant="h4">Green Rides Pune Apps - Download Links</Typography>
      <br />
      <Typography textAlign="left" variant="h6">
        <Link href="/grpuser-qrcode" color="inherit" underline="hover">
          User App
        </Link>
      </Typography>
      <br />
      <Typography textAlign="left" variant="h6">
        <Link href="/grpdriver-qrcode" color="inherit" underline="hover">
          Driver App
        </Link>
      </Typography>
      <br />
      <Typography textAlign="left" variant="h6">
        <Link href="/grppartner-qrcode" color="inherit" underline="hover">
          Partner App
        </Link>
      </Typography>
      <br />
      <Typography textAlign="left" variant="h6">
        <Link href="/grpbusiness-qrcode" color="inherit" underline="hover">
          Business/Corporate App
        </Link>
      </Typography>
      <br /><br />
    </div>
  );
};

export default GrpAppQRCode;
