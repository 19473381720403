// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 100%;
  max-width: 100%;
  margin: 1px;
  padding: 0;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  margin-left: 2;
}
/* 
.card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0);
} */

.card-content {
  display: flex;
  flex-direction: column;
  margin-top: 2;
}

.row {
  display: flex;
  align-items: flex-start;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
  align-content: "center";
  margin-top: 5;
  margin-left: 8px;
}

.text-container {
  flex: 1 1;
  /* margin: 0; */
}

.description {
  /* margin: 0; */
  margin-top: 0;
  margin-bottom: 2;
  font-size: 15px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/booking/CSSStyle/SourceDestination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,WAAW;EACX,UAAU;EACV,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,2BAA2B;EAC3B,cAAc;AAChB;AACA;;;GAGG;;AAEH;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".card {\r\n  width: 100%;\r\n  max-width: 100%;\r\n  margin: 1px;\r\n  padding: 0;\r\n  background-color: #ffffff;\r\n  border-radius: 3px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  transition: box-shadow 0.3s;\r\n  margin-left: 2;\r\n}\r\n/* \r\n.card:hover {\r\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0);\r\n} */\r\n\r\n.card-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-top: 2;\r\n}\r\n\r\n.row {\r\n  display: flex;\r\n  align-items: flex-start;\r\n}\r\n\r\n.icon-container {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-right: 8px;\r\n  align-content: \"center\";\r\n  margin-top: 5;\r\n  margin-left: 8px;\r\n}\r\n\r\n.text-container {\r\n  flex: 1;\r\n  /* margin: 0; */\r\n}\r\n\r\n.description {\r\n  /* margin: 0; */\r\n  margin-top: 0;\r\n  margin-bottom: 2;\r\n  font-size: 15px;\r\n  text-align: left;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
