import React from 'react';
import { IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsApp from '@mui/icons-material/WhatsApp';
const FloatingPhoneButton = () => {
    const phoneNumber = '+918600572727'; // Replace with your phone number

    const handleClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = "+919028249977"; // Replace with actual number
        const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=Hi%2C+%0AI%27m+interested+in+booking+a+cab%2C%0AFrom%3A+PUNE+to+Mumbai+Airport+T2%0ADate%3A%3F%0ATime%3A%3F%0ANumber+of+Passengers%3A%3F+%0ANumber+of+Big+Bags%3A%3F%0APune+Pickup%2FDrop+Location%3A%3F&type=phone_number&app_absent=0`;
        window.open(url, '_blank');
    };

    return (
        <>
            <IconButton
                color="primary"
                onClick={handleWhatsAppClick}
                aria-label="Phone Call"
                sx={{
                    position: 'fixed',
                    right: 20, // Distance from right
                    top: '80%', // Center vertically
                    transform: 'translateY(-50%)', // Align perfectly to the center
                    zIndex: 1000, // Make sure it floats above everything
                    width: 56,
                    height: 56,
                    borderRadius: '50%', // Circle shape
                    backgroundColor: '#05d30f',
                    color: 'white',
                    boxShadow: 3,
                    '&:hover': { backgroundColor: '#05d30f' }
                }}
            >
                <WhatsApp fontSize="large" />
            </IconButton>
            <IconButton
                color="primary"
                onClick={handleClick}
                aria-label="Phone Call"
                sx={{
                    position: 'fixed',
                    right: 20, // Distance from right
                    top: '88%', // Center vertically
                    transform: 'translateY(-50%)', // Align perfectly to the center
                    zIndex: 1000, // Make sure it floats above everything
                    width: 56,
                    height: 56,
                    borderRadius: '50%', // Circle shape
                    backgroundColor: '#05d30f',
                    color: 'white',
                    boxShadow: 3,
                    '&:hover': { backgroundColor: '#05d30f' }
                }}
            >
                <PhoneIcon fontSize="large" />
            </IconButton>
        </>
    );
};

export default FloatingPhoneButton;
