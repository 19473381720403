import React from 'react';
import { IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const PhoneCallButton = () => {
    const phoneNumber = '+918600572727'; // Replace with your phone number

    const handleClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <IconButton
            onClick={handleClick}
            aria-label="Phone Call"
            sx={{
                color: 'white', 
                width: 40,
                height: 40,
                borderRadius: 2, // Square shape
                background: 'linear-gradient(45deg,rgb(5, 211, 15) 30%,rgb(85, 184, 79) 90%)',
                '&:hover': {
                    backgroundColor: '#1ebe57', // Darker green on hover
                },
            }}
        >
            <PhoneIcon />
        </IconButton>
    );
};

export default PhoneCallButton;
