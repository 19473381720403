import { Typography, Box, IconButton, colors } from "@mui/material";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import BookNowButton from "../components/BookNowButton";
import { getLastMinuteCars } from "../components/services/lastMinuteCarService";
import { LastMinuteCar } from "../components/models/LastMinuteCar"
import ImageWithText from "../components/ImageWithText";
import GrpIconButton from "../components/GrpIconButton";


const GrpLastMinuteBooking: React.FC = () => {
  const [carsT2, setCarsT2] = useState<LastMinuteCar[]>([]);
  const [carsPune, setCarsPune] = useState<LastMinuteCar[]>([]);


  const fetchLastMinutesCars = async () => {
    const currentTime = new Date();

    const lastMinuteCars = await getLastMinuteCars();

    let updatedCarsT2: any[] = [];
    let updatedCarsPune: any[] = [];

    const parseTime = (availabilityString: string) => {
      const match = availabilityString.match(/\d+/); // Extracts the first number in the string
      return match ? parseInt(match[0], 10) : 0; // Converts to an integer or returns 0 if no number is found
    };


    lastMinuteCars &&
      lastMinuteCars.forEach((car) => {
        const availabilitySetTime = new Date(car.availabilitySetTime);

        const minutesElapsed = Math.floor((currentTime.getTime() - availabilitySetTime.getTime()) / 60000);
        if (car.availableIn === "BOOKED" || car.availableIn === "NOT AVAILABLE") {
          return
        }

        let initialAvailableIn = parseTime(car.availableIn);

        let updatedAvailableIn = initialAvailableIn - minutesElapsed;

        if (car.carLocation === "PARKED AT T2" && updatedAvailableIn <= 5) {
          updatedAvailableIn = 5;
        } else if ((car.carLocation === "PARKED AT BANER" || car.carLocation === "PARKED AT KONDHWA") && updatedAvailableIn <= 30) {
          updatedAvailableIn = 30;
        }


        let carAvailability = updatedAvailableIn <= 0 ? car.carLocation : `AVAILABLE IN ${updatedAvailableIn} MIN`;

        let newCar = { ...car, availableIn: carAvailability };

        if (car.carLocation === "PARKED AT T2") {
          updatedCarsT2.push(newCar);
        } else {
          updatedCarsPune.push(newCar);
        }
      });

    setCarsT2(updatedCarsT2);
    setCarsPune(updatedCarsPune);
  };

  useEffect(() => {
    fetchLastMinutesCars();
    const interval = setInterval(fetchLastMinutesCars, 60000);
    return () => clearInterval(interval);
  }, []);



  return (
    <>
      <div>
        <img
          src={'/images/SQ_All_640x360/SQ_All_640x360-16.jpg'}
          alt={'Last minute Booking'}
          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
          loading='lazy'
        />
      </div>

      <Box sx={{ border: "2px solid grey", boxShadow: 3, borderRadius: 2, p: 2 }}>

        <Typography gutterBottom variant="h3" align="left">
          Last Minute Cab Booking
        </Typography>
        <Typography gutterBottom variant="h5" align="left">
          Check the live status of available cabs and their estimated arrival time.
        </Typography>

        <Typography gutterBottom variant="body1" align="left">
          You can view the current cab availability status, location, and estimated arrival time. You can book a cab and get special fare. You can also schedule your pickup up to a maximum of 2 hours from the current time.
        </Typography>




        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          {carsT2.length === 0 ? (
            <Grid size={12}>
              <Card sx={{ maxWidth: { xs: "100%", sm: 1200 }, m: "auto", p: 2, boxShadow: 3, position: "relative" }}>
                <ImageWithText imageUrl={"/images/SQ_All_640x360/SQ_All_640x360-12.jpg"} text1={"OOPS!"} text2={"All Cabs are Booked"}></ImageWithText>
              </Card>
            </Grid>
          ) : (
            carsT2.map((car) => (
              <Grid size={{ xs: 12 }} key={car.carId}>
                <Card sx={{ maxWidth: { xs: "100%", sm: 1200 }, m: "auto", p: 2, boxShadow: 3, position: "relative" }}>
                  <ImageWithText imageUrl={"/images/SQ_All_640x360/SQ_All_640x360-12.jpg"} text1={car.carLocation} text2={car.availableIn}></ImageWithText>
                  <Grid container spacing={1} justifyContent="center">
                    <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <BookNowButton
                          fromLocation={"Mumbai International Airport T2"}
                          toLocation={"Pune"}
                          fare={"₹3400/-"}
                          driverId={car.driverId}
                          carId={car.carId}
                          inMinutes={car.availableIn}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                </Card>
              </Grid>
            ))
          )}

          {carsPune.length === 0 ? (
            <Grid size={12}>
              <Card sx={{ maxWidth: { xs: "100%", sm: 1200 }, m: "auto", p: 2, boxShadow: 3, position: "relative" }}>
                <ImageWithText imageUrl={"/images/SQ_All_640x360/SQ_All_640x360-13.jpg"} text1={"OOPS!"} text2={"All Cabs are Booked."}></ImageWithText>
              </Card>
            </Grid>
          ) : (
            carsPune.map((car) => (
              <Grid size={{ xs: 12 }} key={car.carId}>
                <Card sx={{ maxWidth: { xs: "100%", sm: 1200 }, m: "auto", p: 2, boxShadow: 3, position: "relative" }}>
                  <ImageWithText imageUrl={"/images/SQ_All_640x360/SQ_All_640x360-13.jpg"} text1={car.carLocation} text2={car.availableIn}></ImageWithText>

                  <Grid container spacing={1} justifyContent="center">
                    <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <BookNowButton
                          fromLocation={"Pune"}
                          toLocation={"Mumbai International Airport T2"}
                          fare={"₹3640/-"}
                          driverId={car.driverId}
                          carId={car.carId}
                          inMinutes={car.availableIn}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
        <br /> <br />
        <Typography variant="h6" sx={{ color: 'red' }}>
          * Estimated arrival time may vary depending on current traffic.
        </Typography>
        <br /> <br />
        <GrpIconButton name={'BOOK A RIDE IN ADVANCE'} redirectUrl={'/book-your-green-ride'} ></GrpIconButton>

      </Box>
    </>
  );
};

export default GrpLastMinuteBooking;


