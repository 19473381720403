import { Button, Typography } from '@mui/material';
import React from 'react';
import CarouselPartner from '../components/CarouselPartner';

const GrpAppQRCodePartner: React.FC = () => {

  return (
    <div>
      <CarouselPartner></CarouselPartner>
      <br></br>
      <br />
      <Typography textAlign="left" variant="h4">Install Green Rides Partner App</Typography>
      <br />
      <Typography textAlign="left" variant="body1">
        Download Green Rides Pune Partner App!
      </Typography>
      <br />
      <Typography textAlign="left" variant="body1">
        Scan this code with a device
        <br />
        Open the Camera app and point it at this code. Then tap the notification that appears.
      </Typography>

      <br />
      <br />
      <img
        src={'/images/app/grp-partner-qr-code.png'}
        alt="Slide GRP User App"
        style={{ width: '400px', height: 'auto', borderRadius: '10px' }}
        loading='lazy'
      />
      <br />
      <Typography textAlign="left" variant="body1">
        <Button
          variant="contained"
          color="primary"
          href="/app/grp/grp_partner.apk"
          download="grp_partner.apk" // Download attribute
          sx={{
            textTransform: "none",
            padding: "10px 20px",
            fontSize: "20px",
            WebkitTextFillColor: 'white',
            alignItems: "left"
          }}
        >
          Download GRP Partner App
        </Button>
      </Typography>
      <br /><br />
    </div>
  );
};

export default GrpAppQRCodePartner;
