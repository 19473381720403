import React from 'react';
import { Button, Typography } from '@mui/material';


interface ClickImageProps {
    name: string;
    redirectUrl: string;
}

const GrpButton: React.FC<ClickImageProps> = ({ name, redirectUrl }) => {

    return (
        <>
            <Button variant="contained"
                color="primary"
                href={redirectUrl}
                sx={{
                    color: 'white',              // Text color
                     background: 'linear-gradient(45deg,rgb(5, 211, 15) 30%,rgb(85, 184, 79) 90%)',
                    '&:hover': {
                        backgroundColor: '#1ebe57', // Darker green on hover
                    },
                    textTransform: "none",
                    padding: "7px 14px",
                    fontSize: "16px",
                    WebkitTextFillColor: 'white',
                }}
            >{name}</Button>
        </>
    );
};

export default GrpButton;





