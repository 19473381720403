// src/pages/About.tsx
import { Typography } from '@mui/material';
import React from 'react';
import CarouselReview from '../components/CarouselReview';

const GrpPartnerUserApp: React.FC = () => {

  return (
    <div>
      <CarouselReview></CarouselReview>
      <br></br>
      <Typography textAlign="left" variant="h4" gutterBottom>
        Green Rides Pune Partner User App
      </Typography>
      <Typography textAlign="left" variant="body1">
        <br />
        comming soon....
      </Typography>
    </div>
  );
};

export default GrpPartnerUserApp;
