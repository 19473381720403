import { Button, Typography } from '@mui/material';
import React from 'react';
import CarouselUser from '../components/CarouselUser';


const GrpAppQRCodeUser: React.FC = () => {

  return (
    <div>
      <CarouselUser></CarouselUser>
      <br></br>
      <br />
      <Typography textAlign="left" variant="h4">Install Green Rides Pune User App</Typography>
      <br />
      <Typography textAlign="left" variant="body1">
        Download Green Rides Pune User App!
      </Typography>
      <br />
      <Typography textAlign="left" variant="body1">
        Scan this code with a device
        <br />
        Open the Camera app and point it at this code. Then tap the notification that appears.
      </Typography>

      <br />
      <br />
      <img
        src={'/images/app/grp-user-qr-code.png'}
        alt="Slide GRP User App"
        style={{ width: '400px', height: 'auto', borderRadius: '10px' }}
        loading='lazy'
      />
      <br />
      <br />
      <Typography textAlign="left" variant="body1">
        <Button
          variant="contained"
          color="primary"
          href="/app/grp/grp_user.apk"
          download="grp_user.apk" // Download attribute
          sx={{
            textTransform: "none",
            padding: "10px 20px",
            fontSize: "20px",
            WebkitTextFillColor: 'white',
            alignItems: "left"
          }}
        >
          Download GRP User App
        </Button>
      </Typography>


      <br /><br />
    </div>
  );
};

export default GrpAppQRCodeUser;
