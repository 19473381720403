import React from 'react'; // Ensure React is imported
import { IconButton } from '@mui/material'; // Import IconButton correctly
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface WhatsAppIconProps {
    phone: string;
}

// WhatsApp Icon Component
const WhatsAppDriverIcon: React.FC<WhatsAppIconProps> = ({ phone }) => {
    const onWhatsAppIconClick = () => {
        const updatedPhone = phone.replace(/\D/g, '');
        let url = `https://api.whatsapp.com/send?phone=${updatedPhone}`
        window.open(url, '_blank');
    };

    return (
        <IconButton
            size="small"
            color="success"
            onClick={onWhatsAppIconClick}
            sx={{ ml: 1 }}
        >
            <WhatsAppIcon />
        </IconButton>
    );
};

export default WhatsAppDriverIcon;
