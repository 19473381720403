import React from "react";

export const handleAutocompletePlaceService = async (
    autocomplete: google.maps.places.Autocomplete | null,
    setBookingService: (place: google.maps.places.PlaceResult) => void,
) => {
    const place = autocomplete?.getPlace();

    let newAddress = place?.formatted_address || '';

    if (place && newAddress) {
        if (place.formatted_address === "Mumbai, Maharashtra 400099, India") {
            newAddress = `Terminal 2, ${place.name}, ${place.formatted_address}`;
            place.formatted_address = newAddress
        }
        setBookingService(place);
        return;
    }

    // console.warn("No valid address found. Trying Geocode API...");

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: place?.name || '' }, (geocodeResults, geocodeStatus) => {
        if (geocodeStatus === google.maps.GeocoderStatus.OK && geocodeResults?.length) {
            // console.log("Geocode fallback successful:", geocodeResults[0]);
            const geocodeAddress = geocodeResults[0].formatted_address || '';
            setBookingService(geocodeResults[0]);
        } else {
            console.log("Geocode API also failed:", geocodeStatus);
        }
    });
};
