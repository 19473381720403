import { Box, Card, CardContent, Typography } from '@mui/material';

import { mdiBagCarryOnCheck } from '@mdi/js';
import { mdiBagPersonal } from '@mdi/js';
import { mdiWater } from '@mdi/js';
import { mdiPaperCutVertical } from '@mdi/js';
import Icon from '@mdi/react';

interface CustomCardPremiumServiceProps {
    title: string;
    description: string;
}

const CustomCardPremiumService = ({ title, description }: CustomCardPremiumServiceProps) => {
    return (
        <Card
            sx={{
                maxWidth: 400,
                margin: 'auto',
                mt: 5,
                background: 'linear-gradient(135deg, rgb(0, 184, 37),rgb(70, 156, 87))',
                color: 'white',
                borderRadius: '16px',
                boxShadow: 3,
                textAlign: 'center',
            }}
        >
            <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h6" align='left'>{description}
                    <br></br><br></br><Icon path={mdiWater} size={1} /> Water Bottles
                    <br></br><Icon path={mdiPaperCutVertical} size={1} />  Tissues included
                    <br></br><Icon path={mdiBagCarryOnCheck} size={1} /> 4 x 23kg Bags
                    <br></br><Icon path={mdiBagPersonal} size={1} /> 4 x Cabin Bags
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CustomCardPremiumService;
