import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

interface AutoCounterProps {
    targetNumber: number;
    speed?: number;
    increment?: number;
    text?: string;
    extension?: string;
}

const AutoCounter = ({ targetNumber, speed = 100, increment = 1, text = "Counter", extension = "+" }: AutoCounterProps) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (count >= targetNumber) return; // Stop the counter when reaching the target

        const interval = setInterval(() => {
            setCount((prev) => {
                const nextValue = prev + increment;
                if (nextValue >= targetNumber) {
                    clearInterval(interval);
                    return targetNumber; // Stop exactly at target number
                }
                return nextValue;
            });
        }, speed);

        return () => clearInterval(interval);
    }, [count, targetNumber, speed]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
               
                backgroundColor: '#fff', // Light background
            }}
        >
            <Box
                sx={{
                    width: 200,
                    height: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(45deg,rgb(0, 184, 37),rgb(70, 156, 87))',
                    color: 'white',
                    borderRadius: '16px',
                    boxShadow: 3,
                    textAlign: 'center',
                    p: 3,
                }}
            >
                <Typography variant="h4" fontWeight="bold">
                    {count}{extension}
                </Typography>
                <Typography variant="h6">{text}</Typography>
            </Box>
        </Box>

    );
};

export default AutoCounter;
